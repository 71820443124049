.form-container {
  padding: 20px 30px 0;
  color: #07032b;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 40px;
}

.form-container.full-chat {
  padding: 20px 30px 0;
  color: #07032b;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  margin: unset;
  border-radius: 40px;
  background: none;
}

.form-container .go-back-button {
  font-size: 0.8rem;
  background: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding: 0;
  color: #07032b;
}

.form-container .go-back-button .back-icon {
  display: inline-block;
  margin-right: 8px;
  font-size: 0.65rem;
}

.form-container .control-label {
  margin-bottom: 8px;
  font-size: 0.8rem;
  /* text-transform: capitalize; */
  font-weight: 600;
  letter-spacing: 0.03rem;
}

.form-container .form-field-description {
  font-size: 0.7rem;
  margin-bottom: 0.4rem;
  margin-top: -0.3rem;
}

.form-container .form-control {
  border: 1px solid #5F5E5E;
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 20px 15px;
}

.form-control::placeholder {
  color: #6b7085;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #1e4c88;
}

.help-block {
  font-size: 0.7rem;
  margin-top: 0.2rem;
}

form {
  margin-top: 1rem;
}

.submit-button-container {
  text-align: center;
  padding-bottom: 30px;
}

.form-primary-header,
.form-secondary-header {
  font-size: 0.9rem;
  margin-top: 1rem;
  /* font-weight: 600; */
}

.form-secondary-header {
  margin-top: 0.5rem;
}

.form-container .mandatory-star,
.form-container .required {
  color: red;
  font-size: 0.9rem;
  vertical-align: middle;
  margin-left: 2px;
}

.checkbox input {
  width: 0.8rem;
  height: 0.8rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #5F5E5E;
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  vertical-align: text-top;
  margin-right: 0.4rem;
  margin-left: 0;
  vertical-align: middle;
}

.checkbox input:checked:after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: #1e4c88;
  border-radius: 1px;
}

.checkbox {
  font-size: 0.9rem;
}

.checkbox span {
  font-size: 0.8rem;
  vertical-align: middle;
  margin-bottom: 0;
}

.checkbox label {
  margin-bottom: 2px;
}

.form-container .form-group .checkbox input {
  padding: 6px;
}

.form-container .form-group {
  margin-bottom: 10px;
}

.form-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.form-container [type="submit"] {
  background-color: var(--primary-color);
}
