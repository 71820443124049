.bot-chat-bubble-container {
  margin-bottom: 24px;
}

.bot-chat-bubble-container .bot-chat-bubble {
  display: flex;
  position: relative;
  padding-left: 50px;
}

.bot-chat-avatar-container {
  width: 35px;
  height: 35px;
  box-shadow: 0 0 10px rgba(215, 214, 230, 0.5);
  border-radius: 50%;
  margin-right: 8px;
  position: absolute;
  bottom: 0px;
  left: 0;
  min-width: 35px;
  min-height: 35px;
  padding: 0px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bot-chat-avatar-container .bot-avatar-img {
  width: 100%;
}

.bot-chat-message {
  border-radius: 35px 35px 35px 0px;
  /* background: linear-gradient(
    to right,
    #f2c667 0%,
    #f2c667 45.31%,
    #f9b234 100%
  ); */
  background: #f9b234;
  background: var(--primary-color);
  padding: 1rem 2.8rem 1rem 1.6rem;
  color: #07032b;
  color: var(--primary-text-color);
  font-weight: 600;
  font-size: 0.85rem;
}

.bot-chat-media-message {
  border-radius: 35px 35px 35px 0px;
  color: #07032b;
  font-weight: 600;
  font-size: 0.85rem;
}

.bot-chat-message img,
.bot-chat-media-message img {
  max-width: 60%;
  height: auto;
  border-radius: 5px;
  display: block;
  margin-bottom: 5px;
}

.bot-chat-time {
  font-size: 0.6rem;
  color: #bfbfbf;
  font-weight: bold;
  margin-top: 2px;
  padding-left: 48px;
}

.bot-chat-feedback-container {
  margin-left: 12px;
}

.bot-chat-feedback-icon {
  font-size: 0.65rem;
  margin-right: 4px;
  cursor: pointer;
}

.bot-chat-feedback-icon:before {
  color: #a8a8a8 !important;
}

.bot-chat-feedback-icon-selected:before {
  color: #f2c667 !important;
}

.bot-chat-message iframe,
.bot-chat-media-message iframe {
  border: none;
  border-radius: 5px;
  max-width: 300px;
}

.bot-chat-media-message a {
  /* background: linear-gradient(
    to right,
    #f2c667 0%,
    #f2c667 45.31%,
    #f9b234 100%
  ); */
  background-color: #f9b234;
  background-color: var(--primary-color);
}

/* Add animation (fade in the popup) */

@-webkit-keyframes bubbleEntry {
  from {
    transform: (scale(0));
  }
  to {
    transform: (scale(1));
  }
}

@keyframes bubbleEntry {
  from {
    transform: (scale(0));
  }
  to {
    transform: (scale(1));
  }
}

.bot-chat-bubble-container .more-menu-wrapper {
  position: absolute;
  right: 40px;
  top: 5px;
}

.bot-chat-bubble-container .more-menu-wrapper:hover .more-dropdown-menu,
.bot-chat-bubble-container .more-menu-button:hover .more-dropdown-menu {
  display: block;
}

.more-dropdown-menu:active {
  display: block;
}

.bot-chat-bubble-container .more-dropdown-menu .header-dropdown-menu-item {
  font-family: "Muli";
  font-size: 0.85rem;
  color: #07032b;
  cursor: pointer;
  letter-spacing: 0.02rem;
  font-weight: 600;
}

.bot-chat-bubble-container .more-dropdown-menu {
  right: 0;
}

.bot-chat-bubble-container .more-dropdown-menu {
  position: absolute;
  background: #fff;
  top: 30px;
  box-shadow: 0 0 25px rgba(215, 214, 230, 0.6);
  width: max-content;
  max-width: 250px;
  z-index: 200;
  display: none;
  min-width: 100px;
}

.bot-chat-bubble-container .more-dropdown-menu .header-dropdown-menu-item {
  padding: 10px 15px;
  cursor: pointer;
  color: #07032b;
}

.bot-chat-bubble-container
  .more-dropdown-menu
  .header-dropdown-menu-item:hover {
  background: #f2f2ed;
}

.bot-chat-bubble-container .more-menu-button {
  border: none;
  box-sizing: border-box;
  font-size: 1.1rem;
  cursor: pointer;
  background: none;
}

.bot-chat-bubble-container .more-menu-button:focus {
  outline: none;
}

.bot-chat-bubble-container i {
  margin-right: 5px;
  font-size: 0.9rem;
  color: #ffffff;
  color: var(--primary-text-color);
}
