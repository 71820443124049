.trophy-room-page-container {
  background: #f4f5f8;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.trophy-room-page-container .page-header {
  background-color: #fff;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #c6cbd6; */
  box-shadow: 0 0 12px rgba(215, 214, 230, 0.5);
}

.trophy-room-page-container .header-btn {
  border: none;
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: bold;
  color: #0923e6;
  text-align: left;
}

.trophy-room-page-container .header-btn .icon-left-chevron::before {
  color: #0923e6;
  font-size: 0.6rem;
  margin-right: 8px;
}

.trophy-room-page-container .header-btn:hover {
  opacity: 0.7;
}

.trophy-room-page-container .header-heading {
  text-align: center;
  text-transform: capitalize;
}

.trophy-room-page-container .page-content,
.trophy-room-page-container .badge-page-content {
  flex: 1;
  padding: 40px;
  overflow-y: auto;
}

.trophy-room-page-container .page-heading {
  font-size: 1.2rem;
  font-weight: 800;
}

.trophy-room-page-container .page-subheading {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 32px;
}

.trophy-room-page-container .badge-card {
  text-align: center;
  display: inline-block;
  width: 20%;
  padding: 0 1.25rem;
  vertical-align: top;
}

.trophy-room-page-container .badge-img-wrapper {
  width: 120px;
  margin: auto;
}

.trophy-room-page-container .badge-img {
  width: 100%;
  height: auto;
}

.trophy-room-page-container .locked-badge-img {
  filter: grayscale();
}

.trophy-room-page-container .badge-description {
  margin-top: 8px;
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.trophy-room-page-container .badge-name {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 24px;
}

.trophy-room-page-container .badge-date {
  font-size: 0.7rem;
  margin-top: 0px;
}

.trophy-room-page-container .badge-page-img-container {
  width: 300px;
  margin: auto;
}

.trophy-room-page-container .badge-page-img {
  width: 100%;
}

.trophy-room-page-container .badge-page-description {
  font-weight: bold;
  margin-top: 56px;
  text-align: center;
  font-size: 1rem;
}

.trophy-room-page-container .badge-page-date {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
}

.trophy-room-page-container .badge-page-content {
  background: url("../../assets/images/confetti-bg.png");
  background-repeat: repeat;
}

@media only screen and (max-width:1024px) {
  .trophy-room-page-container .badge-card {
    width:25%;
  }
}

@media only screen and (max-width:768px) {
  .trophy-room-page-container .page-header{
    padding: 0.65rem 1rem;
  }
  .trophy-room-page-container .page-heading{
    font-size: 1rem;
    font-weight: 700;
  }
  .trophy-room-page-container .page-content{
    padding: 1rem;
  }
  .trophy-room-page-container .badge-card {
    width: 33%;
    padding: 0 1rem;
  }
  .trophy-room-page-container .badge-img-wrapper {
    width: 80px;
  }

}

@media only screen and (max-width:575px) {
  .trophy-room-page-container .badge-card {
    width: 50%;
  }
}
