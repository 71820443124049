.date-picker-container {
  /* position: relative; */
}

.date-picker-container .DayPicker-Weekday {
  color: #6B7085;
}

.DayPicker-NavButton--next {
  filter: invert(50%) sepia(100%) saturate(200%) hue-rotate(175deg) brightness(80%);
}

.DayPicker-NavButton--prev {
  filter: invert(50%) sepia(100%) saturate(200%) hue-rotate(175deg) brightness(80%);
}

.date-picker-container .DayPicker {
  font-size: 0.8rem;
  background: #fff;
  border-radius: 0.3rem;
}

.date-picker-container .DayPicker-Day {
  height: 30px;
  width: 30px;
  padding: 0;
}

.date-picker-container
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #0ba8a2;
}

.date-picker-button {
  position: relative;
  border: 1px solid #5F5E5E;
  background: #fff;
  padding: 10px 15px 7px;
  border-radius: 3px;
  font-size: 0.8rem;
  max-width: 100%;
  width: 100%;
  text-align: left;
  color: transparent;
  opacity: 0;
}

.date-picker-icon-wrapper {
  background: #1e4c88;
  font-size: 0.7rem;
  padding: 12px;
  position: absolute;
  top: -1px;
  height: 40px;
  width: 40px;
  right: 0px;
  text-align: center;
  border-radius: 0 3px 3px 0;
}

.date-picker-container .date-picker-popover {
  position: absolute;
  top: 0;
  background: #a8a8a8c7;
  z-index: 10; /* correct */
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
}

.display-cell {
  display: table-cell;
  vertical-align: middle;
}

.date-picker-popover-actions {
  margin-top: 1rem;
}

.close-date-picker-button,
.reset-date-picker-button {
  background: #0923E6;
  border-radius: 30rem;
  color: #fff;
  border: none;
  font-size: 0.8rem;
  font-family: "Muli";
  padding: 0.3rem 2.3rem;
  font-weight: bold;
  letter-spacing: 0.02rem;
  margin: 0 0.3rem;
}

.selected-date-preview-item {
  font-size: 0.7rem;
  color: #07032b;
  border: 1px solid #eee;
  padding: 0.2rem 1.3rem 0.25rem 0.5rem;
  border-radius: 10rem;
  position: relative;
  margin-right: 0.5rem;
  font-weight: 600;
  display: inline-block;
  background-color: #fff;
}

.selected-date-preview-item-delete {
  position: absolute;
  right: 8px;
  border: none;
  background: none;
  padding: 0;
  font-size: 0.4rem;
  top: 7px;
  cursor: pointer;
}

.selected-date-preview-item-delete .icon-close::before {
  color: #c34343;
}

.selected-dates-preview {
  margin-top: 0.3rem;
}

.date-picker-input-wrapper {
  position: relative;
  max-width: 100%;
  border-radius: 3px;
  border: 1px solid #5F5E5E;
  background-color: #ffffff;
}

.date-picker-input-helptext {
  font-size: 0.8rem;
  position: absolute;
  top: 8px;
  left: 10px;
}
