.chat-input-container {
  display: flex;
  /* box-shadow: 0 -2px 6px rgba(92, 92, 92, 0.08); */
  padding: 8px 16px 8px;
  justify-content: space-between;
  /* border-top: 1px solid #efefef; */
  background-color: #e4e8f2;
  z-index: 1;
  align-items: center;
  border-radius: 16px;
}

.chat-input-container .avatar-container {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.chat-input-container .avatar-container img {
  width: 100%;
}

.chat-input-container .input-container {
  flex: 1;
  background-color: #F1F2F4;
  padding:10px 12px 8px 8px;
  border-radius: 24px;
}

.chat-input-container .action-container {
  display: flex;
}

.chat-input-container .input-container .input {
  width: 100%;
  box-sizing: border-box;
  font-family: "Muli";
  font-size: 0.9rem;
  color: #07032b;
  border: none;
  height: 31px;
  background-color: #F1F2F4;
  padding:6px 6px 6px 8px;
  border-radius: 24px;
  resize: none;
  max-height: 185px;
  /* border-bottom: 1px solid #eee; */
}

.chat-input-container .input-container .input::-webkit-scrollbar {
  width: 6px;
}
 
.chat-input-container .input-container .input::-webkit-scrollbar-track {
  background-color: #CDD5EF;
  border-radius: 8px;
}
 
.chat-input-container .input-container .input::-webkit-scrollbar-thumb {
  background-color: #9398AB;
  border-radius: 8px;
}

.chat-input-container .input-container .input:focus {
  outline: none;
}

.chat-input-container .action-container .send-button,
.chat-input-container .action-container .end-session-button {
  border: none;
  background: #f9b234;
  background: var(--primary-color);
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  color: #fff;
  color: var(--primary-text-color);
  box-shadow: 0 0 16px rgba(215, 214, 230, 0.8);
  padding: 5px;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  position: relative;
  margin-left: 12px;
}

.chat-input-container .action-container .send-button {
  font-size: large;
}

.chat-input-container .action-container .end-session-button {
  background: #03a8f4;
  color: #fff;
}

.chat-input-container .action-container .send-button .icon-send {
  position: absolute;
  left: 10px;
  top: 8px;
  width: 24px;
  height: 24px;
}

.chat-input-container .action-container .send-button .icon-send::before {
  color: #fff;
  color: var(--primary-text-color);
  display: inline-block;
  transform: rotate(-45deg);
}


.chat-input-container .action-container .send-button[disabled] .icon-send::before {
  color: #000000;
}

.chat-input-container .action-container .send-button:focus {
  outline: none;
}

.chat-input-container .action-container {
  /* padding-top: 5px; */
  /* line-height: 24px; */
}

.chat-input-container .action-container .end-session-button-icon {
  /* height: 13px; */
  /* width: 13px; */
  /* border-radius: 1px; */
  /* background: #fff; */
  position: absolute;
  left: 17px;
  top: 16px;
}

.chat-input-container .end-session-button .icon-close::before {
  color: #ffffff;
  font-size: 0.85rem;
}

.chat-input-container .admin-custom-btn {
  height: 35px;
  margin-top: 6px;
  border-radius: 3px;
  color: #fff;
  border: none;
  padding: 7px 22px 9px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  font-family: "Muli";
  margin-left: 12px;
  background-color: #f9b234;
  /* background-color: var(--button-color); */
  /* box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.2); */
  color: #fff;
  /* color: var(--button-text-color); */
}

.chat-input-container .admin-custom-btn[disabled] {
  background-color: #ccc;
}

.chat-input-container .input-container .input[disabled] {
  opacity: 0.7;
}

.chat-input-container .action-container .send-button[disabled] {
  background-color: #D9D9D9;
  cursor: not-allowed;
  opacity: 0.7;
  font-size: large;
}

/* media queries */

@media screen and (max-width: 768px) {
  .chat-input-container {
    padding: 8px;
  }
}
