.content-preview-file-content {
  background-color: white;
  padding: 8px 16px;
  border-radius: 0 0 0 0;
}

.content-preview-file-content pre {
  white-space: pre-wrap;
  max-height: 200px;
  overflow-y: auto;
}

.option-question-container .range-field-wrapper .textinput-container {
  max-width: 80px;
  margin-left: 16px;
}

.option-question-container .range-field-wrapper .range-slider__wrap {
  flex: 1;
}

.option-question-container .back-button-wrapper {
  display: flex;
  line-height: 30px;
  margin-bottom: 1rem;
}

.option-question-container .header-back-btn {
  /* background: rgba(255, 168, 114, 0.6); */
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 16px;
}

.option-question-container .preview-button-up {
  background-color: #edeff7;
  border-radius: 5px 0 0 0; 
  border: none;
  padding: 6px 30px;
  letter-spacing: 0.03rem;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.option-question-container .preview-button-down {
  background-color: #edeff7;
  border-radius: 0 0 0 5px; 
  border: none;
  padding: 6px 30px;
  letter-spacing: 0.03rem;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.option-question-container .preview-button-wrapper {
  background-color: #f8fafe;
  border-radius: 5px 5px 0 5px; 
}

.option-question-container .preview-button-wrapper-down {
  background-color: #f8fafe;
  border-radius: 5px 0 5px 5px; 
}

.option-question-container .content-preview-wrapper {
  border: 1px solid #f0f1f5;
  border-radius: 5px 5px 5px 5px; 

}