.checkbox-options-container {
  margin-top: 1rem;
  margin-left: 48px;
}

.checkbox-options-container .selected-option-label {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.checkbox-options-container .checkbox-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fefaeb;
  height: 38px;
  margin-bottom: 0.5rem;
}

.checkbox-options-container .checkbox-options .option-label {
  margin-bottom: 0;
  padding: 16px;
}

.checkbox-options-container .checkbox-options .close-btn {
  font-size: 8px;
  padding: 15px;
}

.checkbox-options-container .checkbox-options .close-btn .icon-close:before {
  color: #6b7085;
}

.form-bubble-zindex {
  z-index: 3 !important;
}
