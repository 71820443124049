.chat-messages-header {
  background-color: #fff;
  padding: 0.875rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 15;
}
.main-logo {
  max-width: 80px;
}
.main-logo img {
  width: 100%;
  height: 40px;
  object-fit: contain;
}

.chat-messages-header .left-section {
  display: flex;
}

.header-avatar-container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #eceff5;
  /*padding: 3px;*/
}

.header-avatar-container img {
  width: 100%;
}

.header-avatar-details {
  margin-left: 12px;
  padding-top: 2px;
  line-height: 15px;
  color: #07032b;
  font-weight: 600;
  padding-top: 4px;
}

/* .header-avatar-container::after {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #0da861;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -1px;
} */

.avatar-name {
  font-size: 0.8rem;
}

.right-section {
  display: inline-flex;
}

.avatar-status {
  font-size: 0.7rem;
}

.more-menu-wrapper {
  position: relative;
  height: 100%;
}
.more-menu-wrapper .header-avatar-container > img {
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
.more-menu-wrapper .more-menu-button .icon-down-chevron {
  font-size: 7px;
  margin-left: 0.75rem;
}
.chat-messages-header .more-menu-wrapper:hover .more-dropdown-menu,
.chat-messages-header .more-menu-button:hover .more-dropdown-menu {
  display: block;
}
.chat-messages-header .more-menu-wrapper:focus .more-dropdown-menu,
.chat-messages-header .more-menu-button:focus .more-dropdown-menu {
  display: block;
}

.chat-messages-header .more-dropdown-menu .header-dropdown-menu-item {
  font-family: "Muli";
  font-size: 0.85rem;
  color: #07032b;
  cursor: pointer;
  letter-spacing: 0.02rem;
  padding: 0.6rem;
  display: flex;
  align-items: center;
}

.chat-messages-header .more-dropdown-menu {
  right: 0;
}

.chat-messages-header .more-dropdown-menu {
  position: absolute;
  background: #fff;
  top: 100%;
  box-shadow: 0px 4px 4px 0px #b9b6e0;
  width: max-content;
  max-width: 250px;
  z-index: 200;
  display: none;
  min-width: 100px;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}

.chat-messages-header .more-dropdown-menu .header-dropdown-menu-item:hover {
  background: #f2f2ed;
}

.chat-messages-header .more-menu-button {
  border: none;
  box-sizing: border-box;
  font-size: 1.1rem;
  cursor: pointer;
  background: none;
  height: 100%;
}

.chat-messages-header .more-menu-button:focus {
  outline: none;
}

.chat-messages-header .header-back-btn {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin-right: 12px;
  cursor: pointer;
}

.chat-messages-header .admin-custom-btn {
  border-radius: 3px;
  color: #fff;
  border: none;
  padding: 6px 16px 8px;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  font-family: "Muli";
  margin-right: 12px;
  background-color: #f9b234;
  background-color: var(--button-color);
  box-shadow: 0px 0px 12px rgba(215, 214, 230, 0.5);
  color: #fff;
  color: var(--button-text-color);
  /* border: 1px solid #fff; */
  margin-top: 2px;
}

.chat-messages-header .admin-custom-btn[disabled] {
  background-color: #ccc;
}

.chat-messages-header .notification-btn {
  position: relative;
}

.chat-messages-header .unread-mark {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f9b234;
  /* background-color: var(--primary-color); */
  border-radius: 50%;
  right: 5px;
}

.dropdown-head {
  padding: 0.5rem 0;
}
.dropdown-head > div {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
}
.dropdown-head > div > span {
  color: #6b7085;
  font-size: 0.75rem;
  font-weight: 400;
}
.more-dropdown-menu hr {
  border-top-color: #cdd5ef;
  margin: 0.5rem 0;
}
.menu-icons {
  width: 18px;
  margin-right: 0.5rem;
}
.menu-icons svg {
  width: 17px;
  height: 16px;
}

.is-widget .icon-down-chevron {
  font-size: 7px;
  margin-left: 0.75rem;
}

/* media queries */

@media screen and (max-width: 768px) {
  .header-avatar-details,
  .chat-messages-header .left-section .header-avatar-container,
  .main-logo {
    display: none;
  }
  .chat-messages-header {
    padding: 0.5rem 1rem;
  }
}
