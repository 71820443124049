.info-block {
  margin: 20px 0 32px;
  border: 1px solid #f9b234;
  border: 1px solid var(--primary-color);
  padding: 16px;
  border-radius: 5px;
  font-size: 0.8rem;
  background-color: #fffaf0;
}

.info-block-warning {
  border: 1px solid #f9b234;
  border: 1px solid var(--primary-color);
  background-color: #fffaf0;
}

.info-block .icon-bulb {
  font-size: 0.7rem;
  margin-right: 8px;
}

.info-block .icon-alert-circle {
  font-size: 0.8rem;
  margin-right: 8px;
  color: #b10f0f;
}
