h1 {
  font-size: 1.5rem;
}
.font-600 {
  font-weight: 600 !important;
}

.font-18-px {
  font-size: 18px !important;
}
.choose-organisation .card {
  border: 1px solid #CDD5EF !important;
    border-radius: 8px !important;
    margin-bottom: 1rem;
    box-shadow: 0px 4px 4px #B9B6E0;
}
.choose-organisation .card-header {
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  border-radius: 8px !important;
  border: 0;
  background-color: #FFFFFF;
  cursor: pointer;
}

.choose-organisation .card-header span {
  /* padding: 15px 20px !important; */
}

.clr-pink {
  color: #FF009E;
  font-size: 12px;
}

.font-weight-bold {
  font-weight: 700 !important;
}
.m-0 {
  margin: 0 !important;
}


.accordion-org .card-header {
  > button {
    opacity: 0;
    transition: opacity .35s ease;
    font-weight: 600;
    color: #0923E6;
  }
}

.accordion-org .card-header:hover, .card-header:active  {
  > button {
      opacity: 1;
  }
}

.accordion-org .card-header:after {
  display: none !important;
}

.accordion-org .btn.focus, .accordion-org.btn:focus {
  box-shadow: none !important;
  
}