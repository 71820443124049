.sticker-preview-anchor {
  text-decoration: none;
  border-radius: 5px;
  display: block;
  padding: 8px;
  cursor: pointer;
  background: transparent;
}

.sticker-preview-img {
  width: auto;
  height: auto;
  border-radius: 3px;
  max-height: 200px;
}
