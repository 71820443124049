.no-data-screen-container {
  max-width: 600px;
  margin: 50px auto;
}

.no-data-screen-img-wrapper {
  opacity: 0.6;
}

.no-data-screen-container .no-data-screen-img {
  width: 100%;
}

.no-data-screen-container .no-data-screen-text {
  margin: 32px 0;
  text-align: center;
  font-size: 0.9rem;
  color: #5f5d73;
  font-weight: 500;
}
