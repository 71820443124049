.custom-button {
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 16px rgba(226, 226, 221, 0.7);
  padding: 11px 32px;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  color: var(--primary-text-color);
  cursor: pointer;
}

.custom-button:focus {
  outline: none;
}

.grey-button {
  background: #eceff7;
  color: #4a4a4a;
}

.primary-button {
  /* background-image: linear-gradient(to right, #f2c667 5%, #f9b234 100%); */
  background-color: #f9b234;
  background-color: var(--primary-color);
}

.orange-btn {
  border-radius: 10px;
  border: none;
  margin: 0.75rem;
  background: #f9b234;
  padding: 0.75rem 2.2rem;
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #07032b;
}

.green-button {
  color: #fff !important;
  background: #0d7d29;
}

.orange-btn:focus {
  outline: none;
}

.text-btn {
  border: none;
  background: transparent;
  font-family: Muli;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.03em;
  text-align: center;
  color: #07032b;
  cursor: pointer;
}

.text-btn:focus {
  outline: none;
}

.disabled-button {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #ccc;
}
