.snackbar-container {
  min-width: 250px;
  max-width: 350px;
  border-radius: 10rem;
  padding: 1rem 1.5rem 1.2rem;
  position: fixed;
  z-index: 1000;
  top: 3rem;
  right: 2rem;
  font-size: 0.9rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  -webkit-animation: fadein 0.5s forwards;
  animation: fadein 0.5s forwards;
}

.snackbar-success {
  background: #00864D;
}

.snackbar-info {
  background: #0097c6;
}

.snackbar-error {
  background: #c85e5e;
}

.snackbar-warning {
  background: #e6bc40;
}

.snackbar-bubble {
  background: #5f5d73;
  padding: 12px 24px;
  min-width: 200px;
}

.snackbar-close {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  margin-left: 20px;
}

.snackbar-close .icon-close::before {
  color: #fff;
}

.snackbar-close:focus {
  outline: none;
}

.snackbar-secondary-message {
  font-size: 0.8rem;
}

@-webkit-keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 2rem;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 2rem;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    right: 2rem;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    right: 2rem;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
}
