.drag-match-editor-wrapper .drag-match-definition-list {
  border: 1px solid #eceff7;
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
  max-width: 100%;
}

.drag-match-editor-wrapper .smooth-dnd-container {
  /* border: 1px solid #eceff7; */
  background-color: #fff;
  border-radius: 10px;
  /* padding: 16px; */
  max-width: 100%;
  min-width: 300px;
}

.drag-match-editor-wrapper .drag-card {
  background-color: #fff;
  /* box-shadow: 0 0 16px rgba(215, 214, 230, 0.5); */
  background-color: #f4f6fb;
  padding: 12px;
  border-radius: 5px;
  margin: 4px;
  font-size: 0.8rem;
  cursor: grab;
}

.drag-match-editor {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eceff7;
}

.drag-match-editor table {
  width: 100%;
}

.drag-match-editor td {
  min-width: 300px;
  border: 1px solid #eceff7;
  vertical-align: middle;
  /* border-radius: 5px; */
  font-size: 0.8rem;
}

.drag-match-editor .smooth-dnd-container {
  background-color: transparent;
  border-radius: 3px;
  border: none;
  padding: 0;
  min-height: 40px;
}

/* .drag-match-editor .smooth-dnd-container::before {
  content: "Drop here";
  width: 100px;
  font-size: 0.8rem;
  color: #5f5d73;
  position: absolute;
  z-index: -1;
  top: 8px;
  left: 8px;
} */

.drag-match-editor th {
  border: none;
  font-size: 0.8rem;
  padding: 12px;
  background-color: #eceff7;
  width: 50%;
}

.drag-match-editor-wrapper .smooth-dnd-container.horizontal {
  display: flex;
  flex-wrap: wrap;
}

.drag-match-editor-wrapper .target-drop-placeholder {
  border: 1px dashed #5f5d73;
  border-radius: 3px;
}

.drag-match-editor .target-drop-cell {
  position: relative;
}

.drag-match-editor .target-drop-cell .placeholder {
  position: absolute;
  top: 50%;
  left: 24px;
  font-size: 0.8rem;
  color: #5f5d73;
}
