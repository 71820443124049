.radio-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 5px;
}

.position-type-radio {
  width: 15px;
  height: 15px;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #5F5E5E;
  padding: 6px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0;
}

.position-type-radio:checked:after {
  content: "";
  width: 7px;
  height: 7px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: #1e4c88;
  border-radius: 50%;
}

.radio-label {
  font-size: 0.8rem;
  vertical-align: text-top;
  margin-left: 8px;
  margin-bottom: 0;
}

.quiz-radio-wrapper {
  border: 1px solid #8F94A3;
  border-radius: 5px;
  padding: 10px 20px 10px 40px;
  margin: 0 0 15px;
  cursor: pointer;
  width: 100%;
  background: #fff;
  /* min-width: 70%; */
}

.radio-wrapper .position-type-radio:checked + label {
  border-color: #1e4c88;
}

.quiz-radio {
  font-size: 14px;
}

.absolute-quiz-radio {
  position: absolute;
  left: 15px;
  top: 14px;
}

.radio-wrapper:last-child .quiz-radio-wrapper {
  margin-bottom: 0;
}

.quiz-radio p {
  margin-bottom: 5px;
}
