.all-answerquestions-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px;
  overflow-y: auto;
}
.all-answerquestions-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.all-answerquestions-container::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}
.chat-feature-panel-body {
  padding-left: 15px;
  padding-right: 15px;
}

.all-answerquestions-container .all-answerquestions-decor-img {
  position: absolute;
  right: -16px;
  bottom: -16px;
  height: 60px;
  opacity: 0.3;
}

.all-answerquestions-container .all-answerquestions-decor-img img {
  height: 100%;
  width: auto;
}

.all-answerquestions-container .all-answerquestions-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  /* position: sticky; */
  top: 0;
  /* background: #fff; */
  /* padding: 8px 0; */
}

/* .all-answerquestions-container .all-answerquestions-header .left-partition {
  display: flex;
  line-height: 30px;
} */

/* .all-answerquestions-container .all-answerquestions-header .right-partition {
  padding-top: 4px;
} */

.all-answerquestions-container .all-answerquestions-header .header-heading {
  font-size:1rem;
  font-weight: bold;
}

.all-answerquestions-container .header-back-btn {
  background: rgba(255, 168, 114, 0.6);
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 16px;
}

.all-answerquestions-container .header-back-btn .icon-left-chevron::before {
  color: #cc4f00;
}

.all-answerquestions-container .answerquestion-item {
  margin-top:1.5rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  /* cursor: pointer; */
}

.all-answerquestions-container .answerquestion-item:hover {
  /* background: #eceff7; */
}

.all-answerquestions-container .answerquestion-item-icon {
  font-size: 0.8rem;
  margin-right: 16px;
  margin-bottom: auto;
  margin-top: auto;
}

.all-answerquestions-container .answerquestion-item .left-partition {
  display: flex;
}

.all-answerquestions-container .answerquestion-item-class {
  font-size: 12px;
  font-weight: 400;
}

.all-answerquestions-container .answerquestion-item-question {
  font-size: 14px;
  font-weight: 600;
}

.all-answerquestions-container .answerquestion-item-suggest {
  margin-left: 32px;
}

.all-answerquestions-container
  .answerquestion-item-suggest
  .answerquestion-item-title {
  font-weight: 400;
  font-size: 12px;
  color: #0e0748;
  margin-top: 12px;
  margin-bottom: 4px;
}

.all-answerquestions-container .answerquestion-item-suggest-inputarea {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.all-answerquestions-container
  .answerquestion-item-suggest-inputarea
  .answerquestion-item-suggest-input {
  flex: 1;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 600;
}

.all-answerquestions-container
  .answerquestion-item-suggest-inputarea
  .answerquestion-item-suggest-answer {
  flex: 1;
  margin-right: 24px;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
}

.all-answerquestions-container
  .answerquestion-item-suggest-inputarea
  .answerquestion-item-suggest-confirm {
  background: #0923e6;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: white;
  border: none;
  outline: none;
}

.all-answerquestions-container
  .answerquestion-item-suggest-inputarea
  .answerquestion-item-suggest-cancel {
  background: white;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: #0923e6;
  border-color: #0923e6;
  outline: none;
}
.acknowledge-btn-bottom .custom-icon-label-button{
  border: 1px solid #0923e6;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  background-color: #fff;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .answerquestion-item .right-partition .sugest-ques-btn,
  .answerquestion-item-suggest-confirm-mobile,.acknowledge-btn-bottom{
    display: none;
   }
}
@media screen and (max-width: 768px) {
  .all-answerquestions-container{
    padding: 1.125rem 1rem;
  }
 .answerquestion-item .right-partition .custom-icon-label-button,
 .all-answerquestions-container .answerquestion-item-suggest-inputarea .answerquestion-item-suggest-cancel,
 .all-answerquestions-container .answerquestion-item-suggest-inputarea .answerquestion-item-suggest-confirm,
 .all-answerquestions-header .right-partition .custom-icon-label-button{
  display: none;
 }
}