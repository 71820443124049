.video-preview-wrapper {
  border: 1px solid #eceff7;
  border-radius: 5px;
  padding: 8px;
  background-color: #fff;
}

.video-preview-wrapper iframe {
  border-radius: 3px;
  max-width: 100%;
}

.video-expanded-wrapper {
  text-align: center;
  --primary-text-color: #f9b234;
  max-width: 300px;
}

.video-expanded-wrapper .new-tab-btn {
  font-size: 0.8rem;
}

.video-preview {
  max-width: 320px;
}
