.math-symbol-picker {
  border: 1px solid #eceff7;
  border-radius: 5px;
}

.math-symbol-picker-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 270px;
  padding: 16px 6px 6px 6px;
  will-change: transform;
}

.math-symbol-set-tray {
  /* margin-bottom: 16px; */
  display: flex;
  width: 319px;
  height: 36px;
  white-space: nowrap;
  position: relative;
  /* overflow-x: scroll; */
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 6px 6px;
  border-bottom: 1px solid #eceff7;
}

.math-symbol-set-thumbnail {
  background-color: #eee;
  float: none;
  height: 100%;
  width: 25px;
  margin: 0 0.25%;
  display: inline-block;
  zoom: 1;
  cursor: pointer;
  background-color: #fff;
  border: none;
  padding: 4px;
  /* font-size: 0.7rem; */
  /* font-weight: bold; */
  /* color: #f9b234; */
  /* border: 1px solid #f9b234; */
  border-radius: 2px;
}

.math-symbol-set-thumbnail-active {
  background-color: #f9b234;
  color: #fff;
}

.math-symbol-set-thumbnail-img {
  height: 100%;
  width: auto;
}

.math-symbol-item {
  width: 50px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
  padding: 2px 0;
  height: 30px;
  font-size: 16px;
}

.math-symbol-img {
  width: 100%;
  height: auto;
}

.math-symbol-item:hover {
  background-color: #eceff7;
}
