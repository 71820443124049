.chat-page-wrapper {
  background: #fff;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;
  padding-bottom: 0;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.chat-page-container {
  height: calc(100% - 71px);
  padding-bottom: 0;
  display: flex;
  padding-top: 1rem;
}

.chat-sidebar {
  position: relative;
  display: flex;
  flex-flow: column;
  height: calc(100% - 24px);
  overflow-y: auto;
  background-color: #f5f6fa;
  /* border-right: 1px solid #c6cbd6; */
  overflow: hidden;
  transition: width 0.05s ease-in-out;
  /* box-shadow: 2px 0 12px rgba(215, 214, 230, 0.5); */
  z-index: 12;
  margin: 0px 24px 40px 40px;
  border-radius: 16px;
  width: calc(35% - 80px);
  box-shadow: 0px 4px 4px 0px #b9b6e0;
}

.chat-sidebar-collapsed {
  width: 35px;
  position: relative;
  display: flex;
  flex-flow: column;
  height: calc(100% - 40px);
  background-color: #e4e8f2;
  /* border-right: 1px solid #c6cbd6; */
  transition: width 0.05s ease-in-out;
  /* box-shadow: 2px 0 12px rgb(215 214 230 / 50%); */
  z-index: 12;
  border-radius: 40px;
  margin: 0 40px 40px;
}

.chat-sidebar-content {
  overflow-y: auto;
}

.chat-page-container .new-conversation-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  padding: 14px 32px;
}

.document-preview-wrapper {
  /* background: #f9fafd; */
  overflow: hidden;
  padding: 40px 20px 10px;
  /* margin-right: 20px; */
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  /* margin-left: -20px; */
  z-index: 220;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.12); */
}

.chat-wrapper {
  flex: 1;
  position: relative;
  background-color: #f5f6fa;
  background-size: contain;
  background-position: bottom center;
  display: flex;
  flex-flow: column;
  height: calc(100% - 24px);
  border-radius: 16px;
  transition: width 0.05s ease-in-out;
  margin: 0 40px 40px 0;
  width: calc(65% - 40px);
  box-shadow: 0px 4px 4px 0px #b9b6e0;
}

.chat-wrapper-collapsed {
  position: relative;
  background-size: contain;
  background-position: bottom center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  height: calc(100% - 40px);
  width: calc(100% - 150px);
  transition: width 0.05s ease-in-out;
  background-color: #f5f6fa;
  border-radius: 40px;
  margin: 0 40px 40px 0;
}

.chat-messages-outer-container {
  /* padding: 40px 60px; */
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  /* overflow-y: auto; */
  transition: all 0.3s ease;
  max-height: calc(100% - 95px);
}

.parent-container {
  /* padding: 40px 60px; */
  padding: 2.5rem 1rem; /* Padding changes for mobile responsive */
  overflow-y: auto;
  height: 100%;
  scroll-behavior: smooth;
}

.chat-messages-container::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.chat-messages-container::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}

.chat-footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  z-index: 2;
}
.chat-footer-container .chat-footer {
  background-color: #fff;
}
.chat-page-container .chat-footer .chat-input-wrapper .chat-input-container {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eceff7;
  padding: 8px;
}
.chat-page-container .chat-input-container .input-container {
  background-color: #fff;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #8f94a3;
}
.chat-page-container .chat-input-container .input-container .input {
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 8px;
}

input::placeholder {
  color: #6b7085 !important;
}

.chat-page-container .chat-input-container .action-container .send-button {
  width: 38px;
  height: 38px;
}
.chat-page-container
  .chat-input-container
  .action-container
  .send-button
  .icon-send {
  left: 10px;
  top: 8px;
}

.powerby-nf {
  color: #0923e6;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  top: calc(100% - 25px);
  right: 40px;
  display: flex;
  margin: 5px 0;
}
.powerby-nf span {
  color: #6b7085;
  font-weight: normal;
  margin: 0 3px;
}
.powerby-nf .powerby-nf-click {
  color: #0923e6;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.back-to-chatbot-btn {
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #0923E6;
}
.powerby-nf .accessibility-click {
  border-left: 1px solid #6B7085;
  padding-left: 5px;
  color: #0923e6;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;  
}
.accessibility-container {
  padding: 40px;
}
.accessibility-container iframe {
  margin-top: 10px;
  height: 80vh;
}

#notification-panel {
  width: 100%;
  max-width: 35em;
}

.chat-page-container .notification-panel-header .icon-close {
  font-size: 0.8rem;
}

.chat-page-container .notification-action-container {
  display: flex;
  margin-right: 8px;
}

.chat-page-container .notification-panel-header .icon-setting {
  font-size: 0.9rem;
}

.chat-page-container .notification-panel-header .icon-close::before,
.chat-page-container .notification-panel-header .icon-setting::before {
  color: #07032b;
}
.chat-page-container .panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 16px rgba(214, 215, 231, 0.5);
  box-sizing: content-box;
  padding-top: 55px;
  overflow: scroll;
}

#notification-panel .panel {
  padding-top: 0px;
  padding-bottom: 40px;
}
#notification-panel .send-notification-btn {
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: #f9b234;
  color: #07032b;
  width: 100%;
  height: 40px;
  cursor: pointer;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
}

.chat-page-container .panel .notification-count {
  background-color: #f9b234;
  background-color: var(--primary-color);
  /* margin-left: 8px; */
  font-weight: bold;
  /* position: absolute; */
  /* left: 9rem; */
  font-size: 0.7rem;
  margin-left: 8px;
  height: 18px;
  padding: 0px 5px;
  border-radius: 10px;
  color: #07032b;
  color: var(--text-color);
}

.chat-page-container .panel-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 500px;
  transform: translateX(100%);
  transition: 0.3s ease-out;
  z-index: 250;
}

.chat-page-container .open-panel-wrap {
  transform: translateX(0%);
}

.chat-page-container .notification-panel-header {
  background: white;
  padding: 19px 24px;
  box-shadow: 0 2px 10px rgba(92, 92, 92, 0.15);
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  top: 0;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  border-bottom: 1px solid #eceff7;
}

.chat-page-container .notification-panel-header .close-button {
  float: right;
  margin-top: 2px;
  padding: 0;
}

.chat-page-container .test-bot-panel-header .close-button,
.chat-page-container .test-bot-panel-header .refresh-button,
.chat-page-container .notification-panel-header .see-all-button,
.chat-page-container .notification-panel-header .mark-read-button,
.chat-page-container .notification-panel-header .close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.7rem;
}

.chat-page-container .notification-panel-header .see-all-button {
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
}

.chat-page-container .notification-panel-header .mark-read-button {
  color: #5f5d73;
  vertical-align: text-top;
}

.chat-wrapper .chat-footer .chat-footer-feedback {
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 100%; */
  /* z-index: 1; */
  border-radius: 10px;
  overflow: hidden;
}

.chat-page-container .modal-heading {
  margin-bottom: 10px;
}

.chat-page-container .modal-content {
  max-width: 500px;
}

.topics-container .heading,
.suggested-questions-container .heading {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 600;
  color: #07032b;
  flex: 1;
}

.chat-page-container
  .suggested-questions-container
  .suggested-questions-header {
  display: flex;
  padding: 0 0 10px;
  align-items: center;
}

.chat-page-container .suggested-questions-container .heading {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  color: #0e0748;
  margin-left: 1rem;
}

.suggested-questions-container .suggested-questions-header .view-control-btn {
  font-size: 12px;
  padding: 5px 0;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
}

.suggested-questions-container .view-control-btn .icon-close::before {
  color: #07032b;
}

.chat-page-container .suggested-questions-container {
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  max-height: 530px;
}

.chat-page-container .suggested-questions-body {
  overflow-y: auto;
  max-height: calc(450px - 60px);
}
.chat-page-container .suggested-questions-body::-webkit-scrollbar {
  width: 4px;
  background-color: #6b7085;
  border-radius: 4px;
}
.suggested-questions-collapsed {
  max-height: 0px !important;
  overflow: hidden;
}

.suggested-questions-header:hover .suggested-questions-header .heading {
  color: #f9b234;
  color: var(--primary-color);
}

.topics-container,
.suggested-questions-container {
  /* box-shadow: 0 -2px 10px rgba(92, 92, 92, 0.12); */
  padding: 0px 32px 0px;
  transition: all 0.1s ease-in-out;
  position: relative;
  border-radius: 8px;
}

.topics-container .subtopic-header {
  position: relative;
}

.topics-container .topics-container-nav-button {
  background: none;
  border: none;
  position: absolute;
  font-family: "Muli";
  font-size: 0.8rem;
  color: #07032b;
  font-weight: 600;
  top: -2px;
  left: 18px;
  cursor: pointer;
}

.topics-container .topics-container-nav-button:focus {
  outline: none;
}

.topics-container .topics-container-nav-icon:before {
  color: #07032b;
  font-size: 0.6rem;
  margin-right: 5px;
}

.chat-page-container .logout-button {
  position: absolute;
  right: 40px;
  top: 20px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

video {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}

.not-visible,
.not-visible * {
  opacity: 0;
}

.suggested-questions-container .menu-item-wrapper.active .topic-button {
  background-color: #eceff5;
  font-weight: normal;
}

.chat-page-container .chat-floating-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f9b234;
  background: var(--primary-color);
  box-shadow: 0 0 16px rgba(215, 214, 230, 0.8);
  border: none;
  color: #fff;
  color: var(--primary-text-color);
  cursor: pointer;
  font-size: 1.1rem;
}

.chat-page-container .chat-floating-button .icon-send::before {
  color: #fff;
  color: var(--primary-text-color);
  display: inline-block;
  transform: rotate(-45deg);
}

.topic-button {
  border: none;
  padding: 4px 16px 6px;
  border-radius: 3px;
  font-family: "Muli";
  color: #07032b;
  font-size: 0.7rem;
  cursor: pointer;
  background: #b1bdd6;
  margin: 0 8px 8px 0;
  font-weight: 600;
}

.topic-button-disabled {
  border: none;
  padding: 4px 16px 6px;
  border-radius: 3px;
  font-family: "Muli";
  color: #fff;
  font-size: 0.7rem;
  cursor: pointer;
  background: #ccc;
  margin: 0 8px 8px 0;
  font-weight: 600;
}

.topic-button:focus {
  outline: none;
}

.clear-chat-btn-wrapper {
  text-align: right;
  padding: 0;
  position: fixed;
  bottom: 16px;
  right: 24px;
  max-width: 230px;
  cursor: pointer;
}

.clear-chat-btn-wrapper .action-container {
  position: relative;
}

.clear-chat-btn-wrapper .action-container .admin-custom-btn {
  border-radius: 3px;
  color: #fff;
  border: none;
  padding: 7px 22px 9px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  font-family: "Muli";
  margin-left: 16px;
  background-color: #f9b234;
  /* background-color: var(--button-color); */
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.2);
  color: #fff;
  /* color: var(--button-text-color); */
  border: 1px solid #fff;
}

.chat-page-container .chat-overlay-container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: 14;
  background-color: #f5f6fa;
  border-radius: 40px;
  overflow-y: auto;
}

.chat-page-container .suggested-arrow-container {
  width: 64px;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
  padding: 4px 0;
  height: auto !important;
  top: -4px !important;
}

.chat-page-container .suggested-arrow-container i {
  position: static;
  font-size: 7px;
}

.chat-page-container .suggested-arrow-container i:before {
  color: #0923e6;
}

.suggested-arrow-spaceholder {
  height: 10px;
}

.suggested-arrow-container:before,
.suggested-arrow-container:after {
  position: absolute;
  bottom: 0px;
  width: 11px;
  height: 8px;
  content: " ";
}
.suggested-arrow-container:before {
  left: -9px;
}
.suggested-arrow-container:after {
  right: -9px;
}
.suggested-arrow-container:after,
.suggested-arrow-container:before {
  /* border: 1px solid #c6cbd6; */
}

.suggested-arrow-container:before {
  border-bottom-right-radius: 6px;
  border-width: 0 1px 1px 0;
}

.suggested-arrow-container:after {
  border-bottom-left-radius: 6px;
  border-width: 0 0 1px 1px;
}

.suggested-arrow-container:before {
  /*box-shadow: 2px 1px 0 #e4e8f2;*/
}

.suggested-arrow-container:after {
  /*box-shadow: -2px 1px 0 #e4e8f2;*/
}

.suggested-arrow-container.selected:before {
  box-shadow: 2px 2px 0 #fff;
}

.suggested-arrow-container.selected:after {
  box-shadow: -2px 2px 0 #fff;
}

.btn-opneai {
  background: #e4e8f2;
  color: #0923e6;
  font-size: 12px;
  border-radius: 16px 16px 0 0;
  position: absolute;
  right: 0;
  bottom: 100%;
}

.btn-opneai + .chat-footer {
  border-radius: 16px 0px 16px 16px;
}

.btn-back-to-top {
  background-color: #0923e6;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  position: fixed;
  bottom: 115px;
  right: 57px;
}

.model-badge {
  background-color: #00a67e;
  color: #fff;
  font-size: 0.875rem;
  padding: 2px 16px;
  text-align: center;
  border-radius: 8px 8px 0 0;
  transform: rotate(-90deg);
  position: absolute;
  top: 131px;
  left: -11px;
  transform-origin: top left;
  white-space: nowrap;
}
/* .powered-by {
  padding: 8px 30px 14px;
  font-size: 0.75rem;
  color: #6B7085;
}
.powered-by strong{
  color: #0923E6;
  cursor: pointer;
} */

.suggested-prompts-container .heading {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  color: #07032b;
  flex: 1;
}

.suggested-prompts-container .heading {
  text-align: left;
  font-size: 14px;
}

.suggested-prompts-container .suggested-prompts-header {
  display: flex;
  padding: 16px 5px 8px 0px;
}

.suggested-prompts-container .content {
  display: flex;
}

.suggested-prompts-container .prompts-icon {
  padding: 16px 16px 0px 0px;
}

.suggested-prompts-container .suggested-prompts-header .view-control-btn {
  font-size: 12px;
  padding: 5px 0;
  position: absolute;
  top: 8px;
  right: 16px;
}

.suggested-prompts-container .view-control-btn .icon-close::before {
  color: #07032b;
}

.suggested-prompts-container {
  padding: 0px 16px 16px;
  transition: all 0.2s ease-in-out;
  background: #e6edff;
  max-height: 180px;
  margin: 0rem 2rem 6rem;
  border: 1px solid #cdd5ef;
}

.suggested-prompts-body {
  overflow-y: auto;
  max-height: 100px;
  color: #0923e6;
  font-size: 14px;
  font-weight: 600;
}

.prompts {
  padding: 8px 0px;
  cursor: pointer;
  font-size: 0.875rem;
  color: #0923e6;
  font-weight: 600;
  border-bottom: 1px solid #5f5e5e;
}

.wmi .prompts {
  font-size: 1.125rem;
}

.empty-prompt {
  background-color: #eceff7;
  border-radius: 8px;
  height: 18px;
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 92%);
  }
}

.suggested-prompts-header:hover .suggested-prompts-header .heading {
  color: #f9b234;
  color: var(--primary-color);
}

.suggested-prompts-container {
  /* box-shadow: 0 -2px 10px rgba(92, 92, 92, 0.12); */
  /* padding: 0px 32px 0px; */
  transition: all 0.1s ease-in-out;
  position: relative;
  border-radius: 8px;
}

.chat-page-container .widget-panel-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  transform: translateX(-100%);
  transition: 0.3s ease-out;
  z-index: 250;
}

.chat-page-container .widget-panel-wrap .notification-panel-header {
}

.suggested-prompts-tooltip .tooltip-inner {
  background-color: #ffe6f1;
  border-radius: 4px;
  color: #ff009e;
  font-size: 16px;
  text-align: right;
  max-width: 250px;
}
.suggested-prompts-tooltip .arrow::before {
  border-top-color: #ffe6f1;
}
.suggested-prompts-tooltip .tooltip-inner span {
  display: block;
  text-align: left;
}
.suggested-prompts-tooltip .btn {
  background-color: #0923e6;
  border: 0;
  font-size: 14px;
  padding: 2px 8px;
  margin: 4px 0;
}

.chat-page-container .notification-panel-header .notification-panel-header-actions {
  display: flex;
}

.chat-page-container .notification-panel-header .notification-panel-header-actions .notification-action-container .mark-read-button {
  padding-left: 8px;
}

/* media queries */

@media screen and (max-width: 768px) {
  .chat-wrapper {
    width: 100%;
    margin: 0 !important;
    height: 100%;
    background-color: #f0f4ff;
  }

  .chat-sidebar {
    width: 100%;
    padding: 8px;
    height: 100%;
    margin: 0;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    bottom: 0;
    max-width: 300px;
    background-color: #f0f4ff;
  }
  .sidebar-overlay {
    position: fixed;
    content: "";
    width: calc(100% - 300px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    top: 0;
    right: 0;
  }
  .suggested-questions-container {
    /* padding: 0 16px 4px; */
  }

  .suggested-questions-collapsed {
    max-height: 0px !important;
  }

  .suggested-questions-container .suggested-questions-header {
    padding: 12px 5px 16px;
  }

  .suggested-prompts-container {
    margin: 0rem 1rem 6rem;
  }

  .chat-messages-container {
    padding: 20px;
  }

  .chat-footer {
    margin: 8px;
  }

  .suggested-arrow-container {
    width: 100px;
  }

  .chat-page-container {
    margin: 0.5rem 1.25rem 1rem;
    height: calc(100% - 86px);
    padding-top: 0;
  }
  .chat-sidebar .chat-feature-panel-wrapper {
    padding: 0;
  }
  .parent-container,
  .chat-footer-container {
    padding: 1rem 0.85rem;
  }

  .btn-back-to-top {
    bottom: 105px;
    right: 35px;
  }
  /* .powered-by {
    padding: 8px 16px;
  } */
}

/* .parent-container.custom-scrollbar.listen-sse{
  padding-bottom: calc(100vh - 600px);
} */

.parent-container.custom-scrollbar {
  margin-bottom: 70px;
  /* padding-bottom: calc(100vh - 337px); */
  /* max-height: calc(100% - 70px); */
}

.parent-container.custom-scrollbar.listen-sse {
  padding-bottom: calc(100vh - 337px);
  max-height: calc(100% - 70px);
}

/* .parent-container.custom-scrollbar{
  padding-bottom: calc(100vh - 337px);
  max-height: calc(100% - 70px);
} */

/* .sidebar-menu .sidebar-menu-btn:after {
  content: none !important;
} */
