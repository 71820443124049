.custom-input {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  /* box-shadow: 0px 0px 12px rgba(237, 237, 232, 0.8); */
  border: 1px solid #5F5E5E;
  font-family: Muli;
  font-weight: normal;
  font-size: 0.9rem;
  text-align: left;
  color: #07032b;
  padding: 0.6rem 0.8rem;
  box-sizing: border-box;
}

.custom-input:focus {
  outline: none;
}

.textinput-label {
  font-family: "Muli";
  font-size: 0.8rem;
  font-weight: bold;
  color: #07032b;
  margin-bottom: 10px;
  display: block;
  letter-spacing: 0.02rem;
}

.textinput-description {
  font-size: 0.65rem;
  color: #07032b;
  font-weight: normal;
  margin-top: 3px;
}

.textinput-container .info-message {
  color: #64bfeb;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}
