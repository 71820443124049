.content-preview-file-content {
  background-color: white;
  padding: 8px 16px;
  border-radius: 0 0 0 0;
}

.content-preview-file-content pre {
  white-space: pre-wrap;
  max-height: 200px;
  overflow-y: auto;
}

.option-question-container .range-field-wrapper .textinput-container {
  max-width: 80px;
  margin-left: 16px;
}

.option-question-container .range-field-wrapper .range-slider__wrap {
  flex: 1;
}

.option-question-container .back-button-wrapper {
  display: flex;
  line-height: 30px;
  margin-bottom: 1rem;
}

.option-question-container .header-back-btn {
  /* background: rgba(255, 168, 114, 0.6); */
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 16px;
}

.option-question-container .preview-button-up {
  background-color: #edeff7;
  border-radius: 5px 0 0 0;
  border: none;
  padding: 6px 30px;
  letter-spacing: 0.03rem;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.option-question-container .preview-button-down {
  background-color: #edeff7;
  border-radius: 0 0 0 5px;
  border: none;
  padding: 6px 30px;
  letter-spacing: 0.03rem;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.option-question-container .preview-button-wrapper {
  background-color: #f8fafe;
  border-radius: 5px 5px 0 5px;
}

.option-question-container .preview-button-wrapper-down {
  background-color: #f8fafe;
  border-radius: 5px 0 5px 5px;
}

.option-question-container .content-preview-wrapper {
  border: none;
}

.is-hidden-tablet .document-preview-wrapper,
.widget .document-preview-wrapper {
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  padding: 0;
}

.is-hidden-tablet .option-question-container,
.widget .option-question-container {
  width: 90%;
  height: 100%;
  background: #f0f4ff;
  padding: 40px 20px 10px;
}

.content-preview-wrapper .close {
  cursor: pointer;
}

.content-preview-wrapper .doc-title {
  color: #0e0748;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  padding: 16px 18px;
  border-radius: 2px;
}

.doc-title.active {
  background-color: #0923e6;
  color: white;
}

.reference-preview {
  background-color: white;
  height: 340px;
  overflow-y: scroll;
  border-radius: 2px;
  padding: 6px 12px;
}

.reference-preview-container {
  position: relative;
}

.reference-preview-container > .preview-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: #ECEFF7;
}

.reference-preview::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.doc-title .doc-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc-header-heading {
  color: #0923e6;
}

.document-preview-wrapper .page-subheading {
  font-weight: bold;
  font-size: 1rem;
}

.textinput-container textarea {
  resize: none;
}
.document-preview-wrapper .back-button-wrapper {
  cursor: pointer;
}

.additional-reference {
  display: flex;
  justify-content: space-between;
}

.popover {
  color: #0e0748;
  font-size: 14px;
  border: 0;
  line-height: normal;
  background-color: #ffe6f1;
  padding: 10px;
}

.additional-ref .arrow::before,
.additional-ref .arrow::after {
  border-right-color: #ffe6f1;
}
/* .popover::before{
  content: '';
  border:8px solid transparent;
  border-right-color:#FFE6F1;
  position: absolute;
  right: 100%;
  top: 50%;
  margin-top: -8px;
} */

.view-btn {
  cursor: pointer;
  color: #0923e6;
}

.hover-text {
  position: relative;
  display: inline-block;
}
.answer-textarea textarea {
  /* max-height: 102px; */
}
.additional-ref-modal .close {
  opacity: 1;
}
.additional-ref-modal .modal-title {
  font-size: 1rem;
}
.additional-ref-modal .btn-outline-primary {
  color: #0923e6;
  border-color: #0923e6;
  font-weight: 600;
}

.custom-scrollbar.sidebar-scroll-content {
  height: calc(100% - 90px);
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .doc-title .doc-name {
    max-width: 500px;
  }

  .sidebar-scroll-content {
    height: calc(100% - 90px);
    overflow-y: auto;
  }
}

.reference-preview .text-highlight {
  background-color: rgba(255, 255, 0, .4);
}