.review-wrapper {
  position: relative;
  /* background: #fff url(../../assets/images/chat-bg.png) no-repeat; */
  /* background-color: #fff; */
  background-size: contain;
  background-position: bottom center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  height: calc(100%);
  width: 100%;
  transition: width 0.05s ease-in-out;
}

.reivew-wrapper-collapsed {
  position: relative;
  /* background: #fff url(../../assets/images/chat-bg.png) no-repeat; */
  /* background-color: #fff; */
  background-size: contain;
  background-position: bottom center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  height: calc(100%);
  width: calc(100% - 30px);
  transition: width 0.05s ease-in-out;
}

.quiz-review-container {
  padding: 40px 60px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  overflow-y: auto;
  transition: all 0.3s ease;
  background-color: white;
}

.quiz-review-container .header-back-btn {
  /* background: rgba(255, 168, 114, 0.6); */
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 16px;
}

.quiz-review-container .left-partition {
  display: flex;
  line-height: 30px;
  margin-bottom: 1rem;
}

.review-back-btn {
  background: rgba(255, 168, 114, 0.6);
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 16px;
}

/* media queries */

@media screen and (max-width: 768px) {
  .quiz-review-container {
    padding: 20px;
  }
}
