.intro-panel-container {
  text-align: center;
  position: relative;
}

.intro-panel-container .intro-panel-card {
  text-align: left;
  padding: 30px 24px 15px;
  border-radius: 10px;
  /* display: flex; */
}

.intro-panel-container .logo-container {
  margin-right: 16px;
  width: 100%;
  max-height: 160px;
  display: flex;
    /* max-width: 100px;
    max-width: 160px;
    margin-right: 16px;
    overflow: hidden;
    width: 160px;
    height: 160px;
    border-radius: 50%; */
}

.intro-panel-container .logo-img {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
}

.intro-panel-container .message-container {
  margin-top: 24px;
}

.intro-panel-container .message-container p {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #07032b;
  line-height: 16px;
}

.intro-panel-container .message-container ul {
 list-style-type: disc;
 margin-left: 15px;
}

.intro-panel-container .message-container ol {
  list-style-type: decimal;
  margin-left: 15px;
}

.hide-sidebar-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.9rem;
  color: #07032b;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.show-sidebar-btn {
  right: 0px;
  z-index: 4;
  font-size: 1rem;
  top: 16px;
  background: transparent;
  border-radius: 50%;
  border: none;
  height: 26px;
  width: 36px;
  color: #07032b;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .intro-panel-container .intro-panel-card {
    padding: 20px 16px;
  }
  
}
