.quiz-generator-container {
  padding: 40px;
  box-sizing: border-box;
  /* background: #fff url("../../assets/images/quiz.png") bottom center no-repeat; */
  background-size: 40%;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.quiz-generator-container .go-back-button {
  font-size: 0.8rem;
  background: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding: 0;
  color: #07032b;
  position: absolute;
  left: 30px;
  top: 30px;
}

.quiz-generator-container .go-back-button .back-icon {
  display: inline-block;
  margin-right: 5px;
  font-size: 0.6rem;
}

.quiz-generator-container .quiz-summary {
  text-align: center;
  margin: 20px 10px;
  border-radius: 10px;
}
.quiz-generator-container .quiz-summary:focus {
  outline: auto;
}

.quiz-generator-container .quiz-title {
  font-size: 1rem;
  letter-spacing: 0.02rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.quiz-generator-container .quiz-description {
  font-size: 0.9rem;
  font-family: "Muli";
  letter-spacing: 0.02rem;
}

.quiz-generator-container .quiz-description-important {
  font-size: 0.8rem;
  color: #c34343;
  font-weight: bold;
}

.quiz-generator-container .quiz-navigation-bar {
  padding: 24px 0;
  text-align: center;
  display: flex;
  justify-content: flex-end;
}

.quiz-generator-container .quiz-submit-bar {
  padding: 20px 0;
  text-align: center;
}

.quiz-generator-container .quiz-questions {
  /* background: rgba(256, 256, 256, 0.9); */
  padding: 0px 10px 30px;
  border-radius: 10px;
  overflow-y: auto;
  height: 100%;
}

.quiz-generator-container .quiz-question-text {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.quiz-generator-container .quiz-question-text p {
  margin-bottom: 5px;
}

.quiz-generator-container .inside-quiz-heading {
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.02rem;
}

.quiz-generator-container .custom-input {
  border-color: #cdd8e6;
}

.quiz-generator-container .question-container {
  padding: 24px 0;
}

.quiz-generator-container .all-in-one-question-container {
  /* border-bottom: 1px dashed #5f5d73; */
}

.quiz-generator-container .single-question-manager .question-container {
  min-height: 200px;
}

.quiz-generator-container .next-animation {
  animation: next-slide 0.3s;
}

.quiz-generator-container .previous-animation {
  animation: previous-slide 0.3s;
}

.quiz-generator-container .single-question-manager {
  /* overflow: hidden; */
  /* Updated styling for periodic table in quizzes */
  overflow: visible;
}

.quiz-navigation-button {
  background: #0923e6;
  border-radius: 10px;
  /* padding: 0.6rem 2.2rem; */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border: none;
  width: 45px;
  height: 45px;
  padding: 6px 13px 9px;
  margin-left: 8px;
}

.quiz-navigation-button-icon {
  width: 90%;
  height: auto;
}

.quiz-generator-container .quiz-submit-btn {
  border-radius: 10px;
  margin-left: 8px;
}

.quit-btn-container {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
}

.quit-btn {
  font-size: 0.7rem;
  font-weight: bold;
  color: #c34343;
  border: 1px solid #c34343;
  background-color: #f5f6fa;
  border-radius: 10px;
  padding: 10px 8px 10px 10px;
  letter-spacing: 0.03rem;
  height: 40px;
  width: 40px;
}

.quit-btn .quit-quiz-icon {
  height: 100%;
  width: auto;
}

.quiz-summary .quiz-navigation-bar {
  justify-content: space-evenly;
}

.quiz-generator-container td {
  padding: 5px 16px;
  border: 1px solid #ccc;
}

.quiz-generator-container table {
  border-collapse: collapse;
  background-color: #fff;
}

.quiz-navigation-button[disabled] {
  /* border-color: #ccc;
  color: #ccc; */
  opacity: 50%;
}

.se-image-container img {
  max-width: 100%;
}

.question-score-container {
  text-align: right;
  margin-bottom: 8px;
  font-weight: 600;
  color: #c34343;
  font-size: 0.8rem;
}

.quiz-generator-container .quiz-loading-gif-container {
  width: 100%;
  text-align: center;
  padding: 120px 0;
}

.quiz-generator-container .quiz-loading-gif {
  max-width: 50px;
  margin: auto;
}

.quiz-generator-container .question-number-container {
  min-width: 40px;
  font-weight: bold;
}

.quiz-generator-container .quiz-question-text ul {
  list-style-type: disc !important;
  margin-left: 25px;
  margin-bottom: 5px;
}

.quiz-generator-container .quiz-question-text ol {
  list-style: auto;
  margin-left: 25px;
  margin-bottom: 5px;
}

.quiz-generator-container .quiz-question-text.isStrong strong,
.quiz-generator-container .all-in-one-question-container .quiz-question-text.isStrong strong {
  font-weight: 700 !important;
}

.quiz-generator-container .quiz-question-text.isStrong p,
.quiz-generator-container .all-in-one-question-container .quiz-question-text.isStrong p {
  font-weight: 400 !important;
}

@keyframes next-slide {
  0% {
    transform: translateX(-100px);
    opacity: 0.6;
  }
  20% {
    transform: translateX(-400px);
    opacity: 0.3;
  }
  40% {
    transform: translateX(-600px);
    opacity: 0;
  }
  60% {
    transform: translateX(600px);
    opacity: 0.3;
  }
  80% {
    transform: translateX(300px);
    opacity: 0.7;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes previous-slide {
  0% {
    transform: translateX(100px);
    opacity: 0.6;
  }
  20% {
    transform: translateX(300px);
    opacity: 0.3;
  }
  40% {
    transform: translateX(600px);
    opacity: 0;
  }
  60% {
    transform: translateX(-600px);
    opacity: 0.3;
  }
  80% {
    transform: translateX(-300px);
    opacity: 0.7;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
