.chat-feature-panel-wrapper {
  padding: 0 30px 25px;
}

.chat-feature-panel {
  border-radius: 10px;
  flex-wrap: wrap;
  position: relative;
}

.chat-feature-panel .feature-card-wrapper {
  margin: 0 16px 24px 16px;
}

.chat-feature-panel .decor-img {
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: 60px;
  opacity: 0.3;
}

.chat-feature-panel .decor-img img {
  height: 100%;
  width: auto;
}

.chat-feature-panel .chat-feature-panel-body {
  overflow-y: auto;
  height: 100%;
}

.chat-feature-panel-wrapper .class-list {
  padding: 24px;
  background-color: #f4f6f9;
  border-radius: 10px;
  margin: 0 40px 24px;
}

.chat-feature-panel-wrapper .class-item {
  padding: 16px;
  font-size: 0.8rem;
  border-bottom: 1px solid #eceff7;
  cursor: pointer;
}

.chat-feature-panel-wrapper .class-item-active {
  background-color: #e2ece8;
  border-radius: 10px;
}

.chat-feature-panel-wrapper .class-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.chat-feature-panel-wrapper .class-list-heading {
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0 0 8px;
}

.chat-feature-panel-wrapper .class-item-radio {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #c5d2e3;
  margin-right: 8px;
  vertical-align: middle;
}

.chat-feature-panel-wrapper .class-item-radio-active {
  border: 1px solid #147b51;
  background-color: #147b51;
}

.chat-feature-panel-wrapper .no-class-btn {
  padding: 0;
  margin-bottom: 24px;
}

.chat-feature-panel .sidebar-menu {
  margin-top: 24px;
}

.chat-feature-panel .sidebar-menu-btn {
  display: block;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: 1px;
  font-size: 0.8rem;
  padding: 16px;
  width: 100%;
  text-align: left;
  font-weight: 600;
  display: flex;
}

.chat-feature-panel .sidebar-menu-btn-active {
  color: #0923e6;
  background-color: #e8eaf5;
}

.chat-feature-panel .sidebar-menu-btn:focus {
  outline: none;
}

.chat-feature-panel .sidebar-icon-wrapper {
  width:30px;
}

img.sidebar-icon {
  width:17px;
  height:17px;
  object-fit: contain;
}

.chat-feature-panel .sidebar-icon {
  margin-top: -2px;
}

.chat-feature-panel .sidebar-menu-btn .sidebar-title {
  vertical-align: middle;
}
.custom-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}
@media screen and (max-width: 768px) {
  .chat-feature-panel .feature-card-wrapper {
    margin: 0 0px 24px 0;
  }

  .chat-feature-panel {
    padding: 0 16px 0px;
  }

  .chat-feature-panel-wrapper .class-list {
    margin: 0 24px 24px;
  }
  .chat-input-container .input-container{
    border: 1px solid #CDD5EF;
  }
  .chat-input-container .input-container .input {
    background-color: #F1F2F4;
    border-radius: 8px !important;
    color: #000000;
    font-weight: 400;
  }

  .chat-input-container .action-container .send-button {
    font-size: large;
    width: 40px;
    height: 40px;
  }

  .chat-input-container .action-container .send-button[disabled] {
    background-color: #D9D9D9;
  }

  .chat-input-container .action-container .send-button .icon-send {
    position: absolute;
    left: 11px;
    top: 8px;
  }

  .suggested-arrow-container {
    background-color: #FFFFFF !important;
  }

  .chat-input-container {
    background-color: #FFFFFF;
  }

  .icon-send:before {
    /*color: #000000 !important;*/
  }
  .chat-feature-panel .sidebar-menu{
    margin-top:0;
  }
  .chat-feature-panel .sidebar-menu-btn-active{
    background-color:#F0F4FF; 
  }
}
