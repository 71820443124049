.rating-range {
  margin: 8px 0;
  display: flex;
}

.rating-item {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  margin-right: 8px;
  padding: 5px 0 3px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
}

.rating-item:hover {
  background-color: #eceff7;
}

.rating-item-active-1 {
  border: 1px solid #e53835;
  background-color: #e5383538;
}

.rating-item-active-1:hover {
  background-color: #e5383538;
}

.rating-item-active-2 {
  border: 1px solid #ee6b00;
  background-color: #ee6b003d;
}

.rating-item-active-2:hover {
  background-color: #ee6b003d;
}

.rating-item-active-3 {
  border: 1px solid #fdc00b;
  background-color: #fdc00b38;
}

.rating-item-active-3:hover {
  background-color: #fdc00b38;
}

.rating-item-active-4 {
  border: 1px solid #ccda39;
  background-color: #ccda3942;
}

.rating-item-active-4:hover {
  background-color: #ccda3942;
}

.rating-item-active-5 {
  border: 1px solid #8ac248;
  background-color: #8ac24836;
}

.rating-item-active-5:hover {
  background-color: #8ac24836;
}

.rating-item-img {
  width: 100%;
}
