.all-sessions-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px;
  overflow-y: auto;
}
.all-sessions-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.all-sessions-container::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}

.all-sessions-container .all-sessions-decor-img {
  position: absolute;
  right: -16px;
  bottom: -16px;
  height: 60px;
  opacity: 0.3;
}

.all-sessions-container .all-sessions-decor-img img {
  height: 100%;
  width: auto;
}

.all-sessions-container .all-sessions-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  /* position: sticky; */
  top: 0;
  /* background: #fff; */
  /* padding: 8px 0; */
}

.all-sessions-container .all-sessions-header .left-partition {
  display: flex;
  line-height: 30px;
}

.all-bookmarks-container .all-bookmarks-header .right-partition {
  padding-top: 4px;
}

.all-sessions-container .all-sessions-header .header-heading {
  font-size:1rem !important;
  font-weight: bold;
}

.all-sessions-container .header-back-btn {
  background: rgba(173, 132, 214, 0.6);
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 16px;
}

.all-sessions-container .header-back-btn .icon-left-chevron::before {
  color: #582190;
}

.all-sessions-container .session-item {
  border-bottom: 1px solid #eceff7;
  padding: 12px 0;
  font-size: 0.8rem;
  cursor: pointer;
  position: relative;
}

.all-sessions-container .session-item-flex-wrapper {
  display: flex;
  justify-content: space-between;
}

.all-sessions-container .session-item:hover {
  background: #eceff7;
}

.all-sessions-container .session-item-icon {
  font-size: 0.8rem;
  margin-right: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

.all-sessions-container .session-item .left-partition {
  display: flex;
}

.all-sessions-container .session-item-duration {
  font-size: 0.6rem;
  color: rgb(7, 3, 43, 0.6);
}

.all-sessions-container .active-session-label {
  border: 1px solid #147b51;
  background: #e4fff4;
  border-radius: 3px;
  padding: 2px 8px;
  font-size: 0.7rem;
  height: 25px;
  margin-left: 16px;
}

.all-sessions-container .pagination-btn {
  display: inline-block;
  margin: 16px 8px 8px 0;
  width: 20px;
  height: 20px;
  background-color: #eceff7;
  border-radius: 3px;
  text-align: center;
  font-size: 11px;
  padding-top: 2px;
  cursor: pointer;
}

.all-sessions-container .pagination-btn:hover {
  background-color: #1e4c88;
  color: #fff;
}

.all-sessions-container .pagination-btn-active {
  background-color: #1e4c88;
  color: #fff;
}

.all-sessions-container .topic-name {
  font-size: 11px;
  font-weight: 600;
}

.all-sessions-container .topic-list-wrapper {
  padding-left: 22px;
  margin-top: 5px;
}

.all-sessions-container .session-item:hover .session-tooltip,
.all-sessions-container .session-item-active:hover .session-tooltip {
  display: block;
}

.all-sessions-container .session-tooltip {
  display: none;
  font-size: 0.7rem;
  position: absolute;
  left: calc(100% + 16px);
  bottom: 12px;
  color: #fff;
  /* width: ; */
  background-color: #05254f;
  padding: 8px 8px 10px;
  min-width: 80px;
  border-radius: 3px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  font-weight: 600;
  z-index: 2;
  width: max-content;
}

.all-sessions-container .session-item:hover .session-tooltip::after,
.all-sessions-container .session-item-active:hover .session-tooltip::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 7px 7px 7px 0;
  border-color: transparent #05254f;
  border-radius: 3px;
  display: block;
  width: 0;
  z-index: 1;
  left: -5px;
  bottom: 9px;
}

.all-sessions-container .session-item:hover .session-tooltip::before,
.all-sessions-container .session-item-active:hover .session-tooltip::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 7px 7px 7px 0;
  border-color: transparent #05254f;
  border-radius: 3px;
  display: block;
  width: 0;
  z-index: 0;
  left: -6px;
  bottom: 9px;
}
.all-sessions-container .chat-modal{
  background: rgba(0, 0, 0, 0.35);
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.all-sessions-container .chat-modal .modal-button-container{
  margin-top: 0;
  padding:0.5rem 1rem;
  border-top: 1px solid #ECEFF7;
}
.all-sessions-container .chat-modal .modal-body {
  padding: 0;
}
.confirm-msg{
  padding:1rem;
  color: #0E0748;
  font-size: 0.875rem;
  font-weight: 600;
}
.all-sessions-container .chat-modal .modal-button-container button{ 
  margin: 0 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 4px 8px;
}
.all-sessions-container .chat-modal .modal-button-container button.grey-button{
  background-color: #fff;
  color: #0923E6;
  border: 1px solid #0923E6;
}
.all-sessions-container .chat-modal .modal-button-container button.primary-button{
  background-color: #0923E6;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .all-sessions-container{
    padding: 1.125rem 1rem !important;
  }
}