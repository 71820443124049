:root {
  --topPanelBg: #f0f4ff;
  --menuActive: #0923e6;
  --menuHover: #f0f4ff;
  --chatBodyBg: #ffffff;
  --bookmarkBg: #f0f4ff;
  --menuhoverDesc: #6b7085;
  --menuDesc: #6b7085;
  --menuBlue: #000273;
  --clearBtn: #ffe6f1;
  --clearTxt: #d00026;
  --chatWindowBg: #f0f4ff;

  /* --skyBlue: #F0F4FF;
  --white: #ffffff;
  --menuWhite:#ffffff;
  --panelHeader:#ffffff;
  --bubbleBlue:#0923E6;
  --markRead:#0923E6;
  --markTxt:#FFE6F1;
  --bubbleGrey:#dce5ed; */
}
:root .wmi {
  --topPanelBg: #ededed;
  --menuActive: #406568;
  --menuHover: #8ca3a5;
  --chatWindowBg: #ededed;
  --chatBodyBg: #c8c5c8;
  --bookmarkBg: #ffffff;
  --menuhoverDesc: #ffffff;
  --menuDesc: #6b7085;
  --menuBlue: #ffffff;

  /* --panelHeader:#C8C5C8; */
  /* --markRead:#BC724B; */
  /* --bubbleBlue:#BC724B; */
}

.widget-chat-page-container {
  display: flex;
  flex-direction: column;
  -moz-box-pack: start;
  justify-content: flex-start;
  position: fixed;
  inset: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.chat-right-position-shape {
  /* background: #fff; */
  margin: 0 auto;
  /* width: calc(100% - 24px);
  padding: 12px 16px 16px; */
  position: relative;
  /* border-radius: 40px 40px 16px 40px; */
  height: calc(100% - 30px);
  margin: 12px;
  /* border: 1px solid #dfd7d7; */
}

.chat-left-position-shape {
  background: #fff;
  margin: 0 auto;
  width: calc(100% - 24px);
  padding: 12px 16px 16px;
  position: relative;
  border-radius: 40px 40px 40px 16px;
  height: calc(100% - 30px);
  margin: 12px;
  border: 1px solid #dfd7d7;
}

.widget-bubble {
  background: transparent;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  /* height: calc(100% - 40px); */
  width: 100%;
  /* max-width: 500px; */
  margin: auto;
  display: flex;
  /* box-shadow: 8px -8px 16px rgba(185, 182, 224, 0.4); */
  /* border-radius: 40px 40px 40px 60px; */
  position: relative;
  height: 100%;
}

.widget-chat-sidebar {
  width: 100%;
  /* max-width: 500px; */
  margin: auto;
  position: relative;
  display: flex;
  flex-flow: column;
  height: calc(100%);
  overflow-y: auto;
  background-color: #fff;
  overflow: hidden;
  transition: width 0.05s ease-in-out;
  /* box-shadow: 2px 0 12px rgba(215, 214, 230, 0.5); */
  z-index: 12;
  border-radius: 30px 30px 30px 60px;
}

.widget-chat-sidebar-collapsed {
  width: 30px;
  position: relative;
  display: flex;
  flex-flow: column;
  height: calc(100%);
  background-color: #fff;
  /* border-right: 1px solid #c6cbd6; */
  transition: width 0.05s ease-in-out;
  box-shadow: 2px 0 12px rgba(215, 214, 230, 0.5);
  z-index: 12;
}

.widget-chat-sidebar-content {
  overflow-y: auto;
}

.widget-chat-page-container .new-conversation-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  padding: 14px 32px;
}

.document-preview-wrapper {
  /* background: #f9fafd; */
  overflow: hidden;
  padding: 40px 20px 10px;
  /* margin-right: 20px; */
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  /* margin-left: -20px; */
  z-index: 220;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.12); */
}

.widget-chat-page-container .notification-panel-header .icon-close {
  font-size: 0.8rem;
}

.widget-chat-page-container .notification-action-container {
  display: flex;
}

.widget-chat-page-container .notification-panel-header .icon-setting {
  font-size: 0.9rem;
}

.widget-chat-page-container .notification-panel-header .icon-close::before,
.widget-chat-page-container .notification-panel-header .icon-setting::before {
  color: #07032b;
}
.widget-chat-page-container .panel {
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  background: #fff;
  /* overflow: hidden; */
  /* box-shadow: 0 0 20px rgba(92, 92, 92, 0.2); */
  box-sizing: content-box;
  padding-top: 55px;
  /* overflow: scroll; */
  height: calc(100% - 18px);
  border-radius: 28px;
}
.widget-chat-page-container .notification-lists,
.widget-chat-page-container .chat-bubbles-wrapper {
  height: calc(100% - 83px);
  overflow-y: scroll;
}
.notification-lists .notification-container {
  padding: 1rem;
  background-color: #fff;
  border-bottom-color: #d9d9d9;
}
.notification-lists .notification-container .notification-content-header {
  align-items: flex-start;
}
.notification-lists .option-delete-btn,
.option-delete-btn .icon-delete {
  color: #d00026;
  font-weight: bold;
}
.notification-lists .notification-container .notification-time {
  color: #6b7085;
  font-size: 10px;
  font-weight: 400;
}
.notification-lists .notification-container .notification-title,
.notification-lists .notification-container .notification-content {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}
.notification-lists .notification-container .notification-content {
  font-weight: 400;
}
.notification-lists .notification-checkbox-container .custom-checkbox {
  vertical-align: top;
  margin-right: 0;
}
.notification-lists .notification-checkbox-container .custom-checkbox:after {
  height: 10px;
  width: 10px;
}
.notification-lists::-webkit-scrollbar {
  width: 0px;
}

.widget-chat-page-container .panel .notification-count {
  background-color: var(--primary-color);
  /* margin-left: 8px; */
  font-weight: bold;
  /* position: absolute; */
  /* left: 9rem; */
  font-size: 0.7rem;
  margin-left: 8px;
  height: 18px;
  min-width: 18px;
  line-height: 20px;
  padding: 0px 5px;
  border-radius: 10px;
  color: #fff;
}

.widget-chat-page-container .panel-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  max-width: 100% !important;
  transform: translateX(-110%);
  transition: 0.3s ease-out;
  z-index: 250;
  padding: 12px 12px 20px;
}

.widget-chat-page-container .open-panel-wrap {
  transform: translateX(0%);
}

.widget-chat-page-container .notification-panel-header {
  background: var(--panelHeader);
  padding: 19px 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 2px 4px -2px #b9b6e0;
}

.widget-chat-page-container .notification-panel-header .close-button {
  float: right;
  margin-top: 2px;
  padding: 0;
  position: absolute;
  left: 50px;
}
.widget-chat-page-container .notification-panel-header > div {
  justify-content: center;
}

.widget-chat-page-container .test-bot-panel-header .close-button,
.widget-chat-page-container .test-bot-panel-header .refresh-button,
.widget-chat-page-container .notification-panel-header .see-all-button,
.widget-chat-page-container .notification-panel-header .mark-read-button,
.widget-chat-page-container .notification-panel-header .close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.7rem;
}

.widget-chat-page-container .notification-panel-header .see-all-button {
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
}

.widget-chat-page-container .notification-action-container .mark-read-button {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 7px 16px;
  border: 0;
  border-radius: 0 0 0 28px;
}
.widget-chat-page-container
  .notification-action-container
  .mark-read-button.clear-btn {
  color: var(--clearTxt);
  background-color: var(--clearBtn);
  border-radius: 0 0 28px 0;
}

.widget-chat-page-container .modal-heading {
  margin-bottom: 10px;
}

.widget-chat-page-container .modal-content {
  max-width: 500px;
}

.widget-chat-page-container .logout-button {
  position: absolute;
  right: 40px;
  top: 20px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.widget-chat-page-container .widget-chat-floating-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f9b234;
  background: var(--primary-color);
  box-shadow: 0 0 16px rgba(215, 214, 230, 0.8);
  border: none;
  color: #fff;
  color: var(--primary-text-color);
  cursor: pointer;
  font-size: 1.1rem;
}

.widget-chat-page-container .widget-chat-floating-button .icon-send::before {
  color: #fff;
  color: var(--primary-text-color);
  display: inline-block;
  transform: rotate(-45deg);
}

.clear-chat-btn-wrapper {
  text-align: right;
  padding: 0;
  position: fixed;
  bottom: 16px;
  right: 24px;
  max-width: 230px;
  cursor: pointer;
}

.widget-chat-page-container .chat-overlay-container {
  position: absolute;
  top: 0px;
  left: 16px;
  right: 0;
  bottom: 0;
  z-index: 15;
  background-color: #fff;
  width: calc(100% - 32px);
}

.widget-chat-wrapper {
  position: relative;
  background-size: contain;
  background-position: bottom center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  height: calc(100%);
  width: 100%;
  /* max-width: 500px; */
  margin: auto;
  transition: width 0.05s ease-in-out;
}

.widget-chat-wrapper-collapsed {
  position: relative;
  background-size: contain;
  background-position: bottom center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  height: calc(100%);
  width: calc(100% - 30px);
  transition: width 0.05s ease-in-out;
}

.widget-chat-wrapper .chat-footer .chat-footer-feedback {
  border-radius: 10px;
  overflow: hidden;
}

.auto-scroll {
  overflow-y: auto;
}

.widget-launch-btn-container {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.widget-launch-btn {
  box-shadow: 8px 8px 16px rgba(185, 182, 224, 0.4);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background-color: #fff;
  border: none;
  padding: 20px;
}

.widget-launch-btn:focus {
  outline: none;
}

body {
  background: transparent !important;
}

.widget-launch-btn img {
  max-width: 100%;
  height: auto;
}

.widget-close-btn {
  position: absolute;
  right: 13px;
  top: 8px;
  border: none;
  background-color: transparent;
  z-index: 25;
  padding: 6px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
}

.widget-close-btn .icon-close:before {
  color: black;
}
.sidebar-overlay {
  z-index: 16 !important;
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 300px);
}

.widget-chat-sidebar {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 28px;
  box-shadow: 0px 4px 4px 0px #b9b6e0;
  /* position: fixed;
  left: 0;
  top: 0; */
}
.widget-chat-sidebar .chat-feature-panel-wrapper {
  padding: 0 0px 20px;
}
.widget-chat-sidebar .intro-panel-container {
  background-color: var(--topPanelBg);
}
.widget-chat-sidebar .intro-panel-container .logo-container {
  width: 100%;
  max-height: 100px;
  margin: 0 auto 24px;
  display: flex;
}
.widget-chat-sidebar .intro-panel-container .intro-panel-card {
  padding: 1.5rem 1.875rem;
}
.widget-chat-sidebar .intro-panel-container .message-container {
  margin-top: 12px;
}
.widget-chat-sidebar .intro-panel-container .message-container p {
  font-size: 14px;
  font-weight: 400;
}
.widget-chat-sidebar .intro-panel-container .message-container ol {
  list-style-type: decimal;
  margin-left: 15px;
}
.widget-chat-sidebar .intro-panel-container .message-container ul {
  list-style-type: disc;
  margin-left: 15px;
}
.widget-chat-sidebar .chat-feature-panel {
  padding: 0;
}
.widget-chat-sidebar .sidebar-menu .sidebar-menu-btn {
  font-size: 1rem;
  border-radius: 4px;
  padding: 1rem 1.875rem;
  line-height: normal;
  position: relative;
  align-items: center;
}
.auth-sso-widget .sidebar-menu .sidebar-menu-btn:after {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #6b7085;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  position: absolute;
  right: 1.5rem;
  top: 50%;
  margin-top: -6px;
}

.widget-chat-sidebar .sidebar-menu .sidebar-menu-btn:hover {
  background-color: var(--menuHover);
  color: var(--menuBlue);
}
.widget-chat-sidebar .sidebar-menu .sidebar-menu-btn:hover .title-desc {
  color: var(--menuhoverDesc);
}
.widget-chat-sidebar .sidebar-menu .sidebar-menu-btn.sidebar-menu-btn-active {
  background-color: var(--menuActive);
  color: #fff;
}
.sidebar-menu-btn.sidebar-menu-btn-active:after {
  border-color: #fff;
}
.widget-chat-sidebar .sidebar-menu .sidebar-menu-btn:first-child {
  border-radius: 16px 16px 4px 4px;
}
.widget-chat-sidebar .sidebar-menu .sidebar-menu-btn:last-child {
  border-radius: 4px 4px 16px 16px;
}
/* .sidebar-menu-btn:last-child:after{
  display: none;
} */
.title-desc {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--menuDesc);
}
.sidebar-menu-btn-active .title-desc {
  color: var(--menuWhite) !important;
}
.widget-chat-page-container .chat-body-wrapper {
  box-shadow: 0px 4px 4px 0px #b9b6e0;
  background-color: var(--chatBodyBg);
  border-radius: 28px !important;
  padding: 8px 16px;
}
.widget-chat-page-container .chat-body-wrapper-with-chat {
  background-color: var(--chatWindowBg);
}
.widget-chat-page-container .all-bookmarks-container,
.widget-chat-page-container .all-sessions-container,
.widget-chat-page-container .user-report-page-container {
  border-radius: 16px;
  background-color: var(--bookmarkBg);
}
.widget-chat-page-container .all-bookmarks-header .custom-icon-label-button {
  color: #0923e6;
}
.widget-chat-page-container .all-bookmarks-container .bookmark-item-icon {
  margin-top: 6px;
}
.widget-chat-page-container .chat-input-container .input-container {
  padding: 2px;
  border-radius: 8px;
  border: 0 !important;
  background-color: #fff;
}
.widget-chat-page-container .chat-footer-container {
  left: 0;
  padding: 16px 16px;
}
.widget-chat-page-container .parent-container {
  background-color: var(--white);
  border-radius: 8px 8px 0 0;
  padding: 8px;
  margin-bottom: 60px;
  padding-bottom: 40px;
}
.widget-chat-page-container .parent-container.custom-scrollbar:before {
  content: "";
  width: 100%;
  height: 100%;
  /* background-color: var(--chatWindowBg); */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 28px;
  z-index: -2;
}
.widget-chat-page-container .chat-messages-header {
  background-color: transparent;
  padding: 8px 0px 16px;
}

.widget-chat-page-container .document-preview-wrapper {
  position: absolute !important;
}

.widget-chat-page-container
  .document-preview-wrapper
  .option-question-container {
  width: 100%;
  border-radius: 28px;
}
/* .widget-chat-page-container .text-reply-container{
  background-color: var(--bubbleBlue);
} */
/* .widget-chat-page-container .user-chat-message{
  background-color: var(--bubbleGrey);
} */

/* med ia queries */

@media screen and (max-width: 768px) {
  .widget-chat-wrapper {
    width: 100%;
    margin: 0 !important;
    height: 100%;
  }
}

.custom-data-table .rdt_TableHeadRow {
  background-color: var(--primary-color) !important;
  color: var(--primary-text-color) !important;
}
.custom-data-table .rdt_TableHeadRow .rdt_TableCol_Sortable:hover {
  color: var(--primary-text-color) !important;
}
.widget-chat-page-container .text-reply-container {
  padding: 1rem;
}
.widget-chat-page-container .bot-bubble {
  padding-left: 46px;
}
.widget-chat-page-container .chat-messages-header .notification-btn,
.widget-chat-page-container .chat-messages-header .is-widget {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-chat-page-container .chat-messages-header .is-widget {
  margin-left: 8px;
}
.widget-chat-page-container
  .chat-messages-header
  .is-widget
  .icon-down-chevron {
  margin-left: 0;
}
.widget-chat-page-container .chat-messages-header .header-back-btn {
  width: 38px;
  height: 38px;
  margin-right: 8px;
}
.widget-chat-page-container .chat-footer-container .chat-input-wrapper {
  padding: 8px;
}
.widget-chat-page-container .chat-footer-container .chat-input-container {
  border-radius: 8px;
  background-color: #e6edff;
}
.widget-chat-page-container
  .chat-footer-container
  .chat-input-container
  .input-container
  .input {
  background-color: #fff;
}
.widget-chat-page-container
  .chat-footer-container
  .chat-input-container
  .action-container
  .send-button {
  width: 38px;
  height: 38px;
  background-color: #fff;
  margin-left: 8px;
}
.widget-chat-page-container
  .chat-footer-container
  .chat-input-container
  .action-container
  .send-button
  i::before {
  color: #6b7085;
}

.widget-chat-page-container .suggested-arrow-container {
  width: 64px;
  border-radius: 4px 4px 0 0;
  background-color: #e6edff !important;
  padding: 4px 0;
  height: auto !important;
  top: 6px !important;
}

.widget-chat-page-container .suggested-arrow-container.expanded {
  background-color: #ededed !important;
}

.widget-chat-page-container .suggested-arrow-container i {
  position: static;
  font-size: 7px;
}

.widget-chat-page-container .suggested-arrow-container i:before {
  color: #6b7085;
}

.widget-chat-page-container
  .suggested-questions-parent-container
  .suggested-questions-container {
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  max-height: 340px;
  border-radius: 8px 8px 0px 0px;
}

.widget-chat-page-container
  .suggested-questions-parent-container
  .suggested-questions-container
  .suggested-questions-header {
  display: flex;
  padding: 0 0 10px;
  align-items: center;
}

.widget-chat-page-container
  .suggested-questions-parent-container
  .suggested-questions-container
  .heading {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  color: #0e0748;
  margin-left: 1rem;
}

.widget-chat-page-container .suggested-questions-body {
  overflow-y: auto;
  max-height: calc(450px - 153px);
}

.widget-chat-page-container .suggested-questions-body::-webkit-scrollbar {
  width: 4px;
  background-color: #6b7085;
  border-radius: 4px;
}

.widget-chat-page-container .suggested-questions-parent-container {
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  transition: all 0.2s ease-in-out;
  background-color: #ededed;
  max-height: 410px;
  margin: 8px 8px 0px 8px;
  border-radius: 8px 8px 0px 0px;
}

.widget-chat-page-container .chat-input-wrapper.suggestions-expanded {
  padding: 0px 8px 8px;
}

.widget-chat-page-container
  .chat-input-wrapper.suggestions-expanded
  .chat-input-container {
  border-radius: 0px 0px 8px 8px;
  background-color: #ededed;
}
