.access-denied-page {
  text-align: center;
  padding: 80px 100px 0px;
  overflow: hidden;
  max-height: 100vh;
}

.access-denied-page .heading {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 10px;
}

.access-denied-page .sub-heading {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
}

.access-denied-page .page-description {
  font-size: 20px;
  font-weight: 600;
}

.access-denied-page .hero-image {
  width: 100%;
}

.access-denied-page .logout-btn-container {
  text-align: center;
  margin: 30px 0 0;
}
