.quiz-table-editor-wrapper {
  /* border: 1px solid #bec5d9;
  background-color: #fff; */
  border: 1px solid #bec5d9;
  width: 100%;
  /* border-radius: 5px; */
  /* overflow: hidden; */
}

.quiz-table-editor-wrapper .quiz-table {
  width: 100%;
}

.quiz-table-editor-header-row,
.quiz-table-editor-row {
  /* display: table-row; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.quiz-table-editor-row,
.quiz-table-editor-header-row {
  border-bottom: 1px solid #bec5d9;
  position: relative;
}

.quiz-table-editor-row:last-child {
  border-bottom: none;
}

.quiz-table-editor-header-row .quiz-table-header-cell-wrapper,
.quiz-table-editor-row .quiz-table-row-cell-wrapper {
  /* width: 300px; */
  display: table-cell;
  background-color: #fff;
  vertical-align: top;
  padding: 0;
  /* border-right: 1px solid #f9b234; */
}

.quiz-table-editor-row .column-header-cell-wrapper {
  /* max-width: 300px; */
  display: table-cell;
  background-color: #eceff7;
  vertical-align: top;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 8px;
  border: none;
  /* border-right: 1px solid #f9b234; */
}

.quiz-table-editor-header-row .quiz-table-header-cell-wrapper {
  position: relative;
}

.quiz-table-editor-header-row
  .quiz-table-header-cell-wrapper:hover
  .column-toolbar {
  display: block;
}

.quiz-table-editor-header-row .quiz-table-header-cell-wrapper:last-child,
.quiz-table-editor-row .quiz-table-row-cell-wrapper:last-child,
.quiz-table-editor-row .column-header-cell-wrapper:last-child {
  border-right: none;
}

.quiz-table-editor-header-row .quiz-table-header-cell-wrapper input {
  background-color: #eceff7;
  border-radius: 1px;
  border: none;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 0.8rem;
}

.quiz-table-editor-row .quiz-table-row-cell-wrapper textarea,
.quiz-table-editor-row .column-header-cell-wrapper textarea {
  border-radius: 0px;
  border: none;
  /* border-right: 1px solid #f9b234; */
  font-size: 0.8rem;
  padding: 8px 16px;
  resize: none;
  height: 100%;
}

.quiz-table-editor-row .column-header-cell-wrapper textarea {
  background-color: #eceff7;
  font-weight: bold;
}

.quiz-table-editor-row .quiz-table-row-cell-wrapper:last-child textarea,
.quiz-table-editor-row .column-header-cell-wrapper:last-child textarea {
  border: none;
}

.quiz-table-header-disabled-cell {
  background-color: #eceff7;
  border-radius: 1px;
  border: none;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 0.8rem;
  /* width: 300px; */
  display: table-cell;
}

.quiz-table-editor-row:hover .quiz-table-editor-row-actions {
  display: block;
}

.quiz-table-editor-row-actions {
  position: absolute;
  right: -72px;
  z-index: 1;
  top: 8px;
  display: none;
  padding: 0 8px;
  min-width: 60px;
}

.quiz-table-editor-row-actions .toolbar-btn {
  border: none;
  background-color: transparent;
  height: 30px;
  width: 30px;
  margin-right: 0px;
  cursor: pointer;
  padding: 0;
}

.quiz-table-editor-row-actions .icon-delete {
  color: #c34343;
  font-size: 0.75rem;
}

.column-remove-btn,
.column-convert-header-btn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0 5px;
}

.column-toolbar {
  position: absolute;
  right: 0;
  top: 6px;
  display: flex;
  display: none;
}

.column-remove-btn .icon-delete {
  color: #c34343;
  font-size: 0.75rem;
}

.no-justify-flex {
  justify-content: left !important;
}

.quiz-table-editor-add-column-btn-wrapper {
  padding: 8px 4px;
  min-width: 115px;
}
