.agent-select-container {
  height: 100vh;
  padding: 40px;
  /* background-color: #eceff7; */
}

.agent-select-heading {
  font-size: 1.4rem;
  font-weight: 900;
  margin: 40px 0 32px;
  text-align: center;
}

.agent-options {
  text-align: center;
}

.agent-option-item {
  min-width: 180px;
  padding: 32px 32px 24px;
  display: inline-block;
  /* border: 1px solid #eceff7; */
  border-radius: 5px;
  margin: 24px;
  min-height: 130px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 25px rgba(215, 214, 230, 0.5);
  vertical-align: top;
  background-color: #fff;
  text-align: left;
}

.agent-option-btn {
  display: block;
  /* background-color: #fff; */
  color: #f9b234;
  margin-top: 32px;
  border: none;
  border-radius: 30px;
  padding: 5px 20px 6px;
  font-weight: bold;
  font-size: 0.8rem;
  border: 1px solid #f9b234;

  background: linear-gradient(to right, #f9b234 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.2s ease-out;
}

.agent-option-btn-active {
  background-position: left bottom;
  color: #fff;
}

.agent-description {
  font-weight: normal;
}

.agent-option-item:hover .agent-option-btn {
  /* background-color: #f9b234; */
  color: #fff;
  background-position: left bottom;
}

.agent-option-item:hover {
  box-shadow: 5px 5px 45px rgba(215, 214, 230, 0.7);
}

.agent-option-item-active {
  border: 1px solid #f9b234;
}

.agent-option-btn-disabled {
  background: #eceff7;
  border: 1px solid #eceff7;
  color: #aaa;
}

.agent-option-item-disabled {
  border: 1px solid #eceff7;
}


.accordion-ag .card-header {
  > button {
    opacity: 0;
    transition: opacity .35s ease;
    font-weight: 600;
    color: #0923E6;
  }
}

.accordion-ag .card-header:hover, .card-header:active  {
  > button {
      opacity: 1;
  }
}

.accordion-ag .card-header:after {
  display: none !important;
}

.accordion-ag .btn.focus, .accordion-ag.btn:focus {
  box-shadow: none !important;
  
}