#past-session-panel{
  top: 84px;
}
#past-session-panel .panel {
  padding: 48px 16px 16px;
  overflow: visible;
}

.past-session-panel-header {
  background: white;
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  width: 100%;
}

.past-session-panel-header .close-button {
  float: right;
  padding: 0;
}

.past-session-card .card-heading {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
}

.past-session-card ol,
.past-session-card li {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  margin-left: 0.5rem;
  padding: 0;
  line-height: 1.8;
}

.past-session-card p {
  margin-bottom: 2px;
}

.past-session-card .summary-topic-header,
.past-session-card .quiz-name-header {
  font-weight: bold;
  font-size: 0.9rem;
}

.past-session-card .quiz-result {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.8;
}

.past-session-card .correct-answers-container {
  margin-top: 10px;
}

.past-session-card .correct-answers-container .accordion-section .accordion {
  padding: 9px 40px 9px 20px;
}

.past-session-card .session-duration-container {
  float: right;
  font-size: 0.7rem;
}

.past-session-card .session-duration-container i {
  margin-right: 5px;
}

.panel .chat-bubbles-wrapper {
  margin-top: 2.5rem;
}

.panel .past-session-card {
  margin-top: 1.5rem;
  text-align: left;
}

.past-session-panel-header .see-all-button,
.past-session-panel-header .mark-read-button,
.past-session-panel-header .close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.7rem;
}

.past-session-panel-header .see-all-button {
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
}

.past-session-panel-header .mark-read-button {
  color: #5f5d73;
  vertical-align: text-top;
}

.past-session-card .page-subheading {
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.past-session-card .accordion-content {
  background-color: #f4f5f8;
}

.past-session-card .answer-table-question,
.past-session-card .answer-table-question * {
  text-align: left;
  font-size: 0.8rem !important;
  white-space: pre-wrap;
  word-break: break-word;
}

.past-session-card .custom-icon-label-button {
  padding: 0;
}

.past-session-card table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.past-session-card th,
td {
  padding: 3px 8px;
}

.past-session-card .summary-record-loading-gif {
  width: 20px;
}

.session-heading {
  font-weight: bold;
  font-size: 1rem;
}
#past-session-panel .notification-panel-header{
  box-shadow: none;
  border-bottom: 0;
}
@media only screen and (max-width:768px) {
  #past-session-panel{
    top: 64px;
  }
} 