.header-tabs-wrapper {
  position: fixed;
  top: 16px;
  z-index: 210;
  margin: 0;
  /* left: 110px; */
  /* padding: 0 10px; */
}

.header-tabs-wrapper .header-tab {
  color: #5f5d73;
  background: #ffffff;
  border: none;
  font-size: 0.8rem;
  margin-right: 10px;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 0.02rem;
  cursor: pointer;
}

.header-tabs-wrapper .header-tab-active {
  color: #0923E6;
  background: #E6EDFF;
  font-weight: bold;
}
