/* Style the accordion section */

.accordion-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #5f5e5e;
  border-radius: 5px;
  margin-bottom: 24px;
}

/* Style the buttons that are used to open and close the accordion panel */

.accordion {
  background: none;
  color: #07032b;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.2s ease;
  padding: 16px 40px 16px 20px;
  position: relative;
  text-align: left;
  line-height: 16px;
}

.accordion-section .active {
  border-bottom: 1px solid #eceff7;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.accordion:hover,
.active {
  background: none;
}

/* Style the accordion content title */

.accordion-title {
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.04em;
  margin: 0;
  color: #07032b;
  margin-bottom: 3px;
}

.accordion-title-icon {
  font-size: 20px;
  margin-right: 16px;
}

.accordion-description {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.02rem;
  color: #5f5d73;
}

/* Style the accordion chevron icon */

.accordion-icon {
  transition: transform 0.6s ease;
  color: #07032b;
  position: absolute;
  right: 20px;
  top: 0px;
  bottom: 0;
  margin: auto;
}

/* Style to rotate icon when state is active */

.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */

.accordion-content {
  background: none;
  /* overflow: hidden; */
  transition: max-height 0.6s ease;
  padding: 20px;
}

/* Style the accordion content text */

.accordion-text {
  padding: 18px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

.accordion-summary {
  display: flex;
}

.accordion-title-wrapper {
  margin-top: 2px;
}

.accordion-section .flex-container {
  display: flex;
  justify-content: space-between;
}

.accordion-section .accordion-actions {
  padding: 0px 10px 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accordion-section .accordion-actions .inner {
  display: flex;
  justify-content: space-between;
}

.accordion-actions .custom-button {
  padding: 3px 16px 4px;
  text-transform: capitalize;
  font-size: 0.7rem;
  border-radius: 5px;
  border-width: 1px;
}
