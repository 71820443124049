.bot-reply-container {
  position: relative;
  width: max-content;
  max-width: 90%;
}

.bot-bubble {
  display: flex;
  position: relative;
  padding-left: 50px;
}

.text-reply-container,
.loading-bubble {
  background: #f9b234;
  background: var(--primary-color);
  padding: 1rem 1rem 1rem 1rem;
  color: #07032b;
  color: var(--primary-text-color);
  font-weight: 600;
  font-size: 0.85rem;
  width: max-content;
  max-width: 100%;
  word-break: break-word;
}

.reference-container {
  border-top: 1px solid #f5eff4;
  background: var(--primary-color);
  color: var(--primary-text-color);
  font-size: 14px;
  padding: 1rem 1rem 1rem 1rem;
}

.reference-container .doc-item {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
}

.reference-container .doc-item button {
  text-decoration: underline;
  text-align: left;
  word-break: break-all;
}

.wmi .text-reply-container,
.wmi .user-chat-message,
.wmi .bot-reply-container {
  font-size: 1rem;
}

.loading-bubble,
.first-bubble {
  border-radius: 35px 35px 35px 0px;
}
.first-bubble.view-srouce {
  border-bottom-right-radius: 0px;
}

.followed-bubble {
  border-radius: 0px 35px 35px 35px;
}

/* .text-reply-container p {
  margin-bottom: 3px;
} */

.text-reply-container img {
  max-width: 350px;
  width: 100%;
}

.bot-reply-container a {
  color: #07032b;
  color: var(--primary-text-color);
  text-decoration: underline;
}

.loading-bubble {
  /* margin-bottom: 24px; */
}

.bot-reply-time {
  font-size: 0.7rem;
  color: #0e0748;
  font-weight: 500;
  margin-top: 2px;
  padding-left: 48px;
  position: absolute;
  bottom: -20px;
}

.bot-document-preview-button {
  font-size: 0.6rem;
  font-weight: bold;
  margin-top: 2px;
  padding-right: 48px;
  cursor: pointer;
  position: absolute;
  padding: 5px 13px;
  background: white;
  border-radius: 8px;
  border-color: #cdd5ef;
  bottom: -16px;
  right: 0px;
  gap: 4px;
  white-space: nowrap;
}

.bot-reply-wrapper {
  margin-bottom: 37px;
  position: relative;
}
.btn {
  margin: 0.2rem !important;
}
.bot-followed-reply-wrapper {
  margin-bottom: 8px;
  margin-top: 8px;
}

.bot-reply-container .more-menu-wrapper {
  position: absolute;
  right: 8px;
  top: 5px;
}

.bot-reply-container .more-menu-wrapper:hover .more-dropdown-menu,
.bot-reply-container .more-menu-button:hover .more-dropdown-menu {
  display: block;
}

.bot-reply-container .more-dropdown-menu .header-dropdown-menu-item {
  font-family: "Muli";
  font-size: 0.85rem;
  color: #07032b;
  cursor: pointer;
  letter-spacing: 0.02rem;
  font-weight: 600;
}

.bot-reply-container .more-dropdown-menu {
  right: 0;
}

.bot-reply-container .more-dropdown-menu {
  position: absolute;
  background: #fff;
  top: 30px;
  box-shadow: 0 0 25px rgba(215, 214, 230, 0.6);
  width: max-content;
  max-width: 250px;
  z-index: 200;
  display: none;
  min-width: 100px;
}

.bot-reply-container .more-menu-button {
  border: none;
  box-sizing: border-box;
  font-size: 1.1rem;
  cursor: pointer;
  background: none;
}

.bot-reply-container i {
  font-size: 0.9rem;
  color: #ffffff;
  color: var(--primary-text-color);
}

.bot-reply-feedback-icon {
  font-size: 0.65rem;
  margin-right: 4px;
  cursor: pointer;
}

.bot-reply-feedback-icon:before {
  color: #5F5E5E !important;
  font-size: 10px;
}

.bot-reply-feedback-icon-selected:before {
  color: #f2c667 !important;
}

.bot-reply-feedback-container {
  margin-left: 12px;
}

.bot-chat-avatar-container {
  width: 38px;
  height: 38px;
  box-shadow: 0 0 10px rgba(215, 214, 230, 0.5);
  border-radius: 50%;
  margin-right: 8px;
  position: absolute;
  bottom: 0px;
  left: 0;
  min-width: 35px;
  min-height: 35px;
  padding: 0px;
  background: #fff;
  overflow: hidden;
}

.bot-chat-avatar-container .bot-avatar-img {
  width: 100%;
}

.bot-reply-container a {
  color: #07032b;
  color: var(--primary-text-color);
  text-decoration: underline;
}
.text-reply-container .bot-reply-feedback-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 0;
}
.text-reply-container .bot-reply-feedback-icon {
  margin: 0 1rem;
}
.text-reply-container {
  min-width: 110px;
  cursor: pointer;
  width: 100%;
}
/* new reply chat */
.text-reply-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fefaeb;
  border-radius: 35px 35px 35px 0px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  position: relative;
}
.toltip-description {
  background-color: #ffe6f1;
  padding: 1rem;
  color: #0e0748;
  font-size: 0.875rem;
  border-radius: 8px 0px 8px 8px;
  width: 24rem;
  position: absolute;
  top: 11px;
  right: 100%;
  z-index: 999;
  display: none;
}
.toltip-description:after {
  content: "";
  border: 10px solid transparent;
  border-left-color: #ffe6f1;
  position: absolute;
  left: 100%;
  top: 0;
}
.chatInfo-toltip:hover .toltip-description {
  display: block;
}
.action-wraper {
  position: absolute;
  right: 0;
  top: 100%;
}
.action-wraper .like-msgs {
  font-size: 0.75rem;
  padding: 0 0.75rem;
}
.like-btns-wrap {
  background-color: #fff;
  border-radius: 8px 16px 16px 8px;
  border: 1px solid #cdd5ef;
  margin-top: -14px;
}
.like-btns-wrap .btn + .btn {
  border-left: 1px solid #cdd5ef;
  border-radius: 0;
}
.borkmark-action-right {
  width: 35px;
  position: absolute;
  left: 100%;
  top: 0;
  text-align: center;
}
.borkmark-action-right .btn {
  padding: 0 8px;
}
.borkmark-action-right .btn:focus {
  outline-offset: 2px;
  outline-color: #d71ef7;
  outline-color: #D71EF7;
  outline-width: 2px;
  outline-style: auto;
}
.showBookmarks {
  display: none;
  z-index: 2;
  position: relative;
}
.showBookmarks.shown{
  display: block;
}
.borkmark-action-right:hover .showBookmarks {
  display: block;
}

.text-reply-list ol {
  list-style: auto;
  margin-left: 15px;
}

.text-reply-list ul {
  list-style-type: "-  ";
  margin-left: 15px;
}

.text-reply-list p {
  margin-top: 10px;
}

.text-reply :nth-child(1) {
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .text-reply-new {
    align-items: flex-start;
  }

  .like-btns-wrap {
    margin-top: -6px;
  }

  .chatInfo-toltip {
    position: absolute !important;
    right: 5px;
    top: 15px;
  }

  .bot-reply-feedback-container {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .toltip-description {
    width: 14rem;
  }
  .bot-reply-wrapper {
    margin-bottom: 40px !important;
  }
  .action-wraper .like-msgs {
    font-size: 10px !important;
  }
}

.text-reply-new.show-none .three-dots-feedback {
  display: none;
}
.d-none-imp {
  display: none !important;
}

.text-reply-list pre:has(code) {
  background-color: #44464f;
  color: white;
}

.text-reply-list pre:has(code):first-of-type {
  margin-top: 16px;
  padding-top: 8px;
}

.text-reply-list pre:has(code):last-of-type {
  margin-bottom: 16px;
  padding-bottom: 8px;
}

.text-reply-list pre {
  padding-left: 8px;
  padding-right: 8px;
  line-height: normal;
}

.text-reply-list pre code {
  border-left: 2px solid #e6edff;
  padding-left: 8px;
  display: block;
}

.reference-loader {
  width: 12px;
  height: 12px;
  border: 5px solid #aaa;
  border-bottom-color: var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
