.checkbox-container {
  position: relative;
  font-size: 0.9rem;
}

.checkbox-container .custom-checkbox {
  width: 0.8rem;
  height: 0.8rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #5F5E5E;
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  vertical-align: text-top;
  margin-right: 0.4rem;
  margin-left: 0;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 0;
}

.checkbox-container .custom-checkbox:checked:after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: #1e4c88;
  border-radius: 1px;
}

.checkbox-label {
  font-size: 0.8rem;
  vertical-align: middle;
  margin-bottom: 0;
  cursor: pointer;
}

.quiz-checkbox-wrapper {
  border: 1px solid #8F94A3;
  border-radius: 5px;
  padding: 10px 20px 10px 40px;
  margin: 0 0 15px;
  cursor: pointer;
  width: 100%;
  background: #fff;
  display: inline-block;
  /* min-width: 70%; */
}

.checkbox-container .custom-checkbox:checked + label {
  border-color: #1e4c88;
}

.quiz-checkbox {
  font-size: 14px;
}

.absolute-quiz-checkbox {
  position: absolute !important;
  left: 15px;
  top: 15px;
}

.checkbox-container:last-child .quiz-checkbox-wrapper {
  margin-bottom: 0;
}

.quiz-checkbox p {
  margin-bottom: 5px;
}
