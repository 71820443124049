.sls .bg {
  background-image: url("../../assets/images/sls-bottom-bg.png");
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.blue-button {
  border-radius: 6px;
  border: none;
  background: var(--noodle-factory-v-20-primary-col-6-base, #0923E6);
  padding: 8px 16px;
  color: white;
}
.blue-button[disabled] {
  background: var(--noodle-factory-v-20-grey-grey-4-base, #ECEFF7);
  color: gray;
}


.title {
  color: var(--noodle-factory-v-20-primary-col-6-base, #0923E6);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.sls-select-label {
  color: var(--noodle-factory-v-20-grey-grey-7, #6B7085);
}