body {
  display: block!important;
}

.team-discussion {
  background: #ECEFF7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.custom-icon-label-button {
  color: #6B7085;
  font-size: 16px;
}

.custom-icon-label-button.active {
  color: #0923E6;
  border-bottom: 1px solid #0923E6;
}

.ques-item-container {
  background: white;
  border-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
}

.avar {
  border: 1px solid #eceff5;
  border-radius: 50%;
  height: 40px;
  position: relative;
  width: 40px;
}

.ques-title {
  color:  #0923E6;
  font-size: 16px;
  font-weight: bold;
}

.ques-first-name{
  color: #6B7085;
}

input.search-questions {
  background-image: url("../../assets/images/search-img.svg");
  background-repeat: no-repeat;
  background-position: 11px 50%;
  padding: 7px 15px;
  padding-left: 40px;
  background-color: #fff;
  border: 1px solid #cdd5ef;
  border-radius: 10px;
  color: #0e0748;
  font-size: 14px;
  
  /* height: 47px; */
}

.select-sort .select-target-prefix__control{
  min-width: 180px;
  padding: 0 16px;
  border-color: #0923E6;
}

.select-sort .select-target-prefix__control:hover{
  border-color: #0923E6;
}

.select-target-prefix__value-container .select-target-prefix__single-value {
  color: #0923E6!important;
}


.select-target-prefix__control .select-target-prefix__value-container {
  padding: 0
}


.team-discussion * img{
  object-fit: contain;
}

.spinner-container-dicussion {
  width: 100%;
  height: 300px;
  background-color: white ;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-discussion {
  background: #ECEFF7;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  padding: 16px;
}

.team-discussion-scroll{
  overflow: auto;

}

.icon-button {
  cursor: pointer;
  width: 16px;
  height: 15px;
  object-fit: contain;
}

.sticky-top {
  top: 0;
  position: sticky;
  background-color: #ECEFF7;;
  z-index: 2;
}

.send-button[disabled] {
  background-color: #d9d9d9;
  cursor: not-allowed;
  opacity: .7;
}

.send-button {
  background: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 16px rgba(215,214,230,.8);
  color: #fff;
  color: var(--primary-text-color);
  cursor: pointer;
  height: 35px;
  margin-left: 12px;
  position: relative;
  height: 35px;
  font-size: large;
}

se

.icon-send:before {
  color: black;
  display: inline-block;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.post-input-container {
  position: fixed;
  bottom: 0;
  width: 98%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background-color: #F1F2F4;
  border-radius: 16px;
}

.post-input {
  padding: 4px 8px;
  border-radius: 16px;
  background: white;
  border: 0.6px solid #cdd5ef;
  border-radius: 10px;
  font-size: .9rem;
}

.edit-input {
  padding: 3px;
  background-color: #fff;
  border: 1px solid #cdd5ef;
  border-radius: 10px;
  color: #0e0748;
  font-size: .9rem;
}

.feeback-mobile  {
  max-width: 120px;
  margin: 0px auto;
  padding-bottom: 10px;
}

.posts-container {
  /* padding-bottom: 50px; */
}

/* .chat-discussion-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px;
  overflow-y: auto;
} */

.chat-discussion-container {
  width: 100%;
  height: 100%;
  padding: 26px;
}

.chat-discussion-container .team-discussion {
  width: 100%;
  height: 98%;
  position: relative;
  background-color: #f5f6fa;
  padding: 0px;
} 

.chat-discussion-container .sticky-top  {
  background-color: #f5f6fa;
}

.chat-discussion-container .post-input-container {
  position: sticky;
  border-radius: 10px;
  width: 100%;
}

.chat-discussion-container  .sticky-top {
  padding: 0;
}

.posts-container {
    flex: 1;
    overflow: auto;
}

.questions-container {
  overflow-y: auto;
  overflow-x: hidden;
}