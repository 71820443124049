.quiz-submission-page {
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  overflow-y: auto;
  text-align: center;
  background-color: #fff;
}

.quiz-submission-page h4 {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}

.quiz-submission-page .score-badge {
  width: 100px;
  height: 100px;
  margin: 24px auto 32px;
  background-image: url("../../assets/images/badge.png");
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 30px 0;
}

.quiz-submission-page .score-container {
  width: 100%;
  text-align: center;
}

.quiz-submission-page .review-comment {
  padding: 0.3rem 0.4rem;
}

.quiz-submission-page .button-container {
  text-align: center;
  margin: 16px 0 32px;
}

.quiz-submission-page .charts-container {
  margin: 24px 0 24px;
  background: #f8f9fa;
  border-radius: 5px;
  padding: 24px 0;
}

.quiz-submission-page .charts-container .pie-container {
  height: 250px;
  width: 100%;
  text-align: center;
  max-width: 255px;
  box-sizing: border-box;
  margin: auto;
  display: inline-block;
  padding: 0 20px;
}

.quiz-submission-page .charts-container .chart-heading {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.03rem;
  margin: 10px 0 0;
}

.quiz-submission-page .charts-container .pie-stat-item {
  font-size: 0.8rem;
  font-weight: 600;
}

.quiz-submission-page .total-score-number {
  font-size: 0.65rem;
}

.quiz-submission-page h5 {
  font-weight: bold;
  font-size: 0.8rem;
}

.quiz-submission-page .correct-answers-container {
  margin-top: 40px;
}

.quiz-submission-page .correct-answers-container .accordion-section {
  background: #fff;
}

.quiz-submission-page .correct-answers-container .accordion-content {
  background-color: #fbfbfb;
}

.quiz-submission-page .answer-table-question,
.quiz-submission-page .answer-table-question * {
  text-align: left;
  font-size: 0.8rem !important;
  white-space: pre-wrap;
}

.quiz-submission-page .answer-table-subquestion {
  font-weight: bold;
}

.quiz-submission-page .main-action-container {
  margin: 32px 0;
}

.quiz-submission-page .condition-message {
  font-weight: bold;
  font-size: 0.8rem;
  text-align: left;
}

.quiz-submission-page .condition-message a {
  color: blue;
}

.quiz-submission-page .condition-buttons {
  text-align: left;
  border-bottom: 1px solid #eceff7;
  padding-bottom: 8px;
}

.quiz-submission-page .condition-buttons .suggestion-button {
  margin-left: 0;
  margin-right: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  letter-spacing: 0.01rem;
}

.quiz-submission-page table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.quiz-submission-page th,
td {
  padding: 3px 8px;
}

.correct-answer-record-container .notify-block {
  color: #26ad6a;
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 8px;
}

.quiz-submission-page .send-review-action-panel {
  position: fixed;
  width: 67%;
  padding: 13px;
  top: 0;
  z-index: 21;
  box-shadow: 0 0 15px rgba(215, 214, 230, 0.5);
  background: #1e4c88;
  color: #fff;
  right: 0;
  animation: slide-down 0.4s ease;
  font-size: 0.8rem;
  font-weight: 600;
}

.quiz-submission-page .send-review-btn {
  border-radius: 3px;
  border: none;
  padding: 7px 16px 9px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-left: 16px;
  font-family: "Muli";
  background-color: #fbc300;
  color: #07032b;
}

.quiz-submission-page .cancel-review-btn {
  border-radius: 3px;
  border: none;
  padding: 7px 16px 9px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-left: 8px;
  font-family: "Muli";
  background-color: #eceff7;
  color: #07032b;
}

.quiz-submission-page .checkbox-label {
  color: #1e4c88;
  font-weight: bold;
}

.quiz-submission-page .checkbox-container {
  display: inline-block;
}

.quiz-submission-page .question-conditions-container {
  background-color: #eceff7;
  padding: 0px 24px;
  margin: 16px 16px 0;
}

.quiz-submission-page .page-main-heading {
  font-weight: bold;
  font-size: 0.9rem;
  text-align: left;
  margin: 0 0 16px;
  text-decoration: underline;
}

.essay-feed-back {
  text-align: left;
  white-space: pre-wrap;
}

.answer-table-question.bullet ul {
  display: flex;
  flex-direction: column;
}

.answer-table-question.question ul {
  list-style-type: disc !important;
  margin-left: 20px;
  margin-bottom: 5px;
}

.answer-table-question.question ol {
  list-style-type: auto;
  margin-left: 20px;
  margin-bottom: 5px;
}

.quiz-submission-page .condition-message .isStrong strong {
  font-weight: 700 !important;
}

.quiz-submission-page .condition-message .isStrong p {
  font-weight: 600 !important;
} 

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
