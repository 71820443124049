.markdown-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: #07032b;
  color: var(--text-color);
  margin-bottom: 16px;
  display: block;
  letter-spacing: 0.02rem;
}

.markdown-description {
  font-size: 0.7rem;
  color: #5f5d73;
  color: var(--light-text-color);
  font-weight: normal;
  margin-top: 3px;
}

.markdown-container .markdown-error {
  color: #c34343;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}

.rdw-editor-main {
  border-radius: 2px;
  background: #fff;
  padding: 16px;
  font-size: 0.9rem;
  border-radius: 5px;
  /* border: 1px solid #eceff7; */
}

.rdw-image-alignment,
.rdw-image-imagewrapper {
  position: relative;
  width: 100%;
  max-width: 500px;
}

.rdw-image-alignment img,
.rdw-image-imagewrapper img {
  width: 100%;
}

.rdw-editor-main figure {
  margin: 0;
}

.markdown-container {
  width: 100%;
}

.markdown-editor-wrapper {
  border: 1px solid #5F5E5E;
  border-radius: 5px;
  background-color: #fff;
}

.rdw-editor-toolbar {
  border: none;
  border-bottom: 1px solid #eceff7;
  margin-bottom: 0;
  border-radius: 5px;
}

.markdown-toolbar-option img {
  width: 16px;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.rdw-dropdown-wrapper:hover,
.rdw-option-wrapper:hover {
  box-shadow: none;
  border-color: #bec5d9;
}

.rdw-dropdown-wrapper,
.rdw-option-wrapper {
  border-color: #eceff7;
}

.rdw-link-modal,
.rdw-emoji-modal,
.rdw-sticker-modal,
.rdw-periodic-modal {
  border-color: #f9fafd;
  box-shadow: 3px 3px 8px rgba(215, 214, 230, 0.5);
}

.rdw-image-modal-size {
  display: none;
}

.markdown-container input {
  border: 1px solid #bec5d9 !important;
  border-radius: 3px !important;
  margin-bottom: 0 !important;
}

.rdw-link-modal {
  height: auto;
  width: 280px;
}

.rdw-emoji-modal,
.rdw-sticker-modal {
  height: auto;
  width: 335px;
}

.rdw-periodic-modal {
  /* height: auto;
  width: 580px; */

  /* Updated styling for periodic table in quizzes */
  overflow: auto;
}

.link-modal-action-bar {
  padding: 32px 16px 8px;
  text-align: center;
}

.link-modal-action-bar .custom-icon-label-button {
  margin: 0 16px;
  color: #1e4c88 !important;
}

.rdw-variable-menu {
  width: 180px;
  padding: 0;
}

.rdw-variable-menu-item {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.8rem;
}

.rdw-variable-menu-item:hover {
  background-color: #f1f1f1;
}
