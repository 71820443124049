.chat-body-wrapper {
  position: relative;
  /* margin: auto; */
  background: #fff;
  /* box-shadow: 0 0 16px rgba(92, 92, 92, 0.12); */
  display: flex;
  flex-flow: column;
  height: 100%;
  z-index: 1500;
  border-radius: 5px;
  max-width: 100%;
  /* max-height: calc(100% - 31px); */
  border-radius: 25px 25px 30px 60px;
  background-color: rgba(236, 239, 247, 0.5);
}

.chat-sidebar-content {
  overflow-y: auto;
  height: 100%;
}

.chat-page-container .new-conversation-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  padding: 14px 32px;
}

.widget .document-preview-wrapper {
  /* background: #f9fafd; */
  overflow: hidden;
  padding: 40px 20px 10px;
  /* margin-right: 20px; */
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  /* margin-left: -20px; */
  z-index: 220;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.12); */
  /* height: 100%; */
  bottom: 0;
  display: flex;
  left: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999999;
}

.chat-messages-container {
  padding: 40px 60px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.chat-messages-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.chat-messages-container::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}

.chat-footer {
  background-color: #e4e8f2;
  border-radius: 16px;
  /* border: 1px solid #c6cbd6; */
  /* overflow: hidden; */
  margin: 16px;
  /* z-index: 100; */
  /* position: relative; */
}

#notification-panel {
  width: 100%;
  max-width: 35em;
}

.chat-page-container .notification-panel-header .icon-close {
  font-size: 0.8rem;
}

.chat-page-container .notification-action-container {
  display: flex;
  margin-right: 8px;
}

.chat-page-container .notification-panel-header .icon-setting {
  font-size: 0.9rem;
}

.chat-page-container .notification-panel-header .icon-close::before,
.chat-page-container .notification-panel-header .icon-setting::before {
  color: #07032b;
}
.chat-page-container .panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 16px rgba(214, 215, 231, 0.5);
  box-sizing: content-box;
  padding-top: 55px;
  overflow: scroll;
}

#notification-panel .panel {
  padding-top: 0px;
  padding-bottom: 40px;
}
#past-session-panel .panel {
  padding-top: 0px;
  padding-bottom: 40px;
}
#notification-panel .send-notification-btn {
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: #f9b234;
  color: #07032b;
  width: 100%;
  height: 40px;
  cursor: pointer;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
}

.chat-page-container .panel .notification-count {
  background-color: #f9b234;
  background-color: var(--primary-color);
  /* margin-left: 8px; */
  font-weight: bold;
  /* position: absolute; */
  /* left: 9rem; */
  font-size: 0.7rem;
  margin-left: 8px;
  height: 18px;
  padding: 0px 5px;
  border-radius: 10px;
  color: #07032b;
  color: var(--text-color);
}

.chat-page-container .panel-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  /* width: 500px; */
  transform: translateX(100%);
  transition: 0.3s ease-out;
  z-index: 250;
}

.chat-page-container .open-panel-wrap {
  transform: translateX(0%);
}

.chat-page-container .notification-panel-header {
  background: white;
  padding: 19px 24px;
  box-shadow: 0 2px 10px rgba(92, 92, 92, 0.15);
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  top: 0;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  border-bottom: 1px solid #eceff7;
}

.chat-page-container .notification-panel-header .close-button {
  float: right;
  margin-top: 2px;
  padding: 0;
}

.chat-page-container .test-bot-panel-header .close-button,
.chat-page-container .test-bot-panel-header .refresh-button,
.chat-page-container .notification-panel-header .see-all-button,
.chat-page-container .notification-panel-header .mark-read-button,
.chat-page-container .notification-panel-header .close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.7rem;
}

.chat-page-container .notification-panel-header .see-all-button {
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
}

.chat-page-container .notification-panel-header .mark-read-button {
  color: #5f5d73;
  vertical-align: text-top;
}

.chat-wrapper .chat-footer .chat-footer-feedback {
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 100%; */
  /* z-index: 1; */
  border-radius: 10px;
  overflow: hidden;
}

.chat-page-container .modal-heading {
  margin-bottom: 10px;
}

.chat-page-container .modal-content {
  max-width: 500px;
}

.topics-container .heading,
.suggested-questions-container .heading {
  text-align: center;
  font-size: 0.7rem;
  font-weight: 600;
  color: #07032b;
  flex: 1;
}

.suggested-questions-container .heading {
  text-align: left;
  font-size: 0.8rem;
}

.suggested-questions-container .suggested-questions-header {
  display: flex;
  padding: 16px 5px 16px;
  cursor: pointer;
}

.suggested-questions-container .suggested-questions-header .view-control-btn {
  font-size: 12px;
  padding: 5px 0;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 16px;
}

.suggested-questions-container .view-control-btn .icon-close::before {
  color: #07032b;
}

.suggested-questions-container {
  padding: 0px 24px 16px;
  transition: all 0.2s ease-in-out;
  background: #e4e8f2;
  max-height: 250px;
}

.suggested-questions-body {
  overflow-y: auto;
  max-height: 200px;
}

.suggested-questions-collapsed {
  max-height: 0px !important;
  overflow: hidden;
}

.suggested-questions-header:hover .suggested-questions-header .heading {
  color: #f9b234;
  color: var(--primary-color);
}

.topics-container,
.suggested-questions-container {
  /* box-shadow: 0 -2px 10px rgba(92, 92, 92, 0.12); */
  padding: 0px 32px 0px;
  transition: all 0.1s ease-in-out;
  position: relative;
  border-radius: 8px;
}

.topics-container .subtopic-header {
  position: relative;
}

.topics-container .topics-container-nav-button {
  background: none;
  border: none;
  position: absolute;
  font-family: "Muli";
  font-size: 0.8rem;
  color: #07032b;
  font-weight: 600;
  top: -2px;
  left: 18px;
  cursor: pointer;
}

.topics-container .topics-container-nav-button:focus {
  outline: none;
}

.topics-container .topics-container-nav-icon:before {
  color: #07032b;
  font-size: 0.6rem;
  margin-right: 5px;
}

.chat-page-container .logout-button {
  position: absolute;
  right: 40px;
  top: 20px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

video {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}

.not-visible,
.not-visible * {
  opacity: 0;
}

.suggested-questions-container .menu-item-wrapper.active .topic-button {
  background-color: #eceff5;
  font-weight: normal;
}

.chat-page-container .chat-floating-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f9b234;
  background: var(--primary-color);
  box-shadow: 0 0 16px rgba(215, 214, 230, 0.8);
  border: none;
  color: #fff;
  color: var(--primary-text-color);
  cursor: pointer;
  font-size: 1.1rem;
}

.chat-page-container .chat-floating-button .icon-send::before {
  color: #fff;
  color: var(--primary-text-color);
  display: inline-block;
  transform: rotate(-45deg);
}

.topic-button {
  border: none;
  padding: 4px 16px 6px;
  border-radius: 3px;
  font-family: "Muli";
  color: #07032b;
  font-size: 0.7rem;
  cursor: pointer;
  background: #b1bdd6;
  margin: 0 8px 8px 0;
  font-weight: 600;
}

.topic-button-disabled {
  border: none;
  padding: 4px 16px 6px;
  border-radius: 3px;
  font-family: "Muli";
  color: #fff;
  font-size: 0.7rem;
  cursor: pointer;
  background: #ccc;
  margin: 0 8px 8px 0;
  font-weight: 600;
}

.topic-button:focus {
  outline: none;
}

.clear-chat-btn-wrapper {
  text-align: right;
  padding: 0;
  position: fixed;
  bottom: 16px;
  right: 24px;
  max-width: 230px;
  cursor: pointer;
}

.clear-chat-btn-wrapper .action-container {
  position: relative;
}

.clear-chat-btn-wrapper .action-container .admin-custom-btn {
  border-radius: 3px;
  color: #fff;
  border: none;
  padding: 7px 22px 9px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  font-family: "Muli";
  margin-left: 16px;
  background-color: #f9b234;
  /* background-color: var(--button-color); */
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.2);
  color: #fff;
  /* color: var(--button-text-color); */
  border: 1px solid #fff;
}

.chat-page-container .chat-overlay-container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: 14;
  background-color: #f5f6fa;
  border-radius: 40px;
  overflow: hidden;
  overflow-y: auto;
}

.suggested-arrow-container {
  width: 220px;
  margin: auto;
  font-size: 6px;
  text-align: center;
  margin-top: 2px;
  cursor: pointer;
  background-color: #e4e8f2;
  border-radius: 26px 26px 0 0;
  /* border: 1px solid #c6cbd6; */
  border-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top:2px;
  z-index: 1;
  height: 12px;
  /* padding: 2px 0 0; */
  transition: all 0.1s ease-in-out;
}

.suggested-arrow-container:hover {
  height: 15px;
  top:0px;
}

.suggested-arrow-container .icon-up-chevron {
  position: relative;
  top: 3px;
}

.suggested-arrow-container .icon-up-chevron::before {
  color: #5f5d73;
}

.suggested-arrow-spaceholder {
  height: 10px;
}

.suggested-arrow-container:before,
.suggested-arrow-container:after {
  position: absolute;
  bottom: 0px;
  width: 11px;
  height: 8px;
  content: " ";
}
.suggested-arrow-container:before {
  left: -9px;
}
.suggested-arrow-container:after {
  right: -9px;
}
.suggested-arrow-container:after,
.suggested-arrow-container:before {
  /* border: 1px solid #c6cbd6; */
}

.suggested-arrow-container:before {
  border-bottom-right-radius: 6px;
  border-width: 0 1px 1px 0;
}

.suggested-arrow-container:after {
  border-bottom-left-radius: 6px;
  border-width: 0 0 1px 1px;
}

.suggested-arrow-container:before {
  /*box-shadow: 2px 1px 0 #e4e8f2;*/
}

.suggested-arrow-container:after {
  /*box-shadow: -2px 1px 0 #e4e8f2;*/
}

.suggested-arrow-container.selected:before {
  box-shadow: 2px 2px 0 #fff;
}

.suggested-arrow-container.selected:after {
  box-shadow: -2px 2px 0 #fff;
}

/* media queries */

@media screen and (max-width: 768px) {
  .chat-wrapper {
    width: 100%;
    margin: 0 !important;
    height: 100%;
    /* padding: 8px !important; */
  }

  .chat-sidebar {
    width: 100%;
    padding: 8px;
    height: 100%;
  }

  .suggested-questions-container {
    /* padding: 0 16px 4px; */
  }

  .suggested-questions-collapsed {
    max-height: 0px !important;
  }

  .suggested-questions-container .suggested-questions-header {
    padding: 12px 5px 16px;
  }

  .chat-messages-container {
    padding: 20px;
  }

  .chat-footer {
    margin: 8px;
  }

  .suggested-arrow-container {
    width: 100px;
  }
}

.widget-chat-page-container .user-button-container {
  float: none;
  max-width: 100%;
}

.widget-chat-page-container .suggestion-button {
  font-size: 0.7rem !important;
}

.wmi .suggestion-button {
  font-size: 1.125rem !important;
}

@media screen and (max-width: 768px) {
  .user-chat-bubble-hasbutton-container {
    padding: 8px !important;
  }
}

#past-session-panel .header-tabs-wrapper {
  position: static !important;
}
#past-session-panel .chat-bubbles-wrapper, #past-session-panel .past-session-card {
  padding: 20px 16px 16px;
}

#past-session-panel .notification-lists{
  margin-top: 20px;
  height: calc(100% - 46px) !important;
}