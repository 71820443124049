.shadow-card {
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(215, 214, 230, 0.5);
  padding: 25px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.clickable-card {
  cursor: pointer;
}

.clickable-card:hover {
  opacity: 0.5;
}
