.chat-page-container > .chat-modal {
  padding-top: 0px;
}

.chat-modal {
  background-color: rgba(236, 239, 247, 0.8);
  z-index: 20; /* correct */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

/* Modal content */
.chat-modal .modal-content {
  background-color: #fff;
  width: 90%;
  margin: auto;
  padding: 10px;
  /* box-shadow: 0px 0px 15px rgba(215, 214, 230, 0.5); */
  border-radius: 10px;
  padding: 0px;
  position: relative;
  max-height: 500px;
  border: none;
  max-width: 400px;
}

.chat-modal .mt-20 {
  margin-top: 20px;
}

.chat-modal .modal-title {
  font-size: 1.1rem;
  letter-spacing: 0.03rem;
  font-family: "Muli";
  font-weight: bold;
  color: #07032b;
  padding-left: 15px;
  padding-top: 15px;
}

.chat-modal .modal-subtitle {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.02rem;
  color: #6a6a6a;
  margin-top: 5px;
}

.chat-modal .modal-body {
  padding: 32px 32px 30px;
}

.chat-modal .modal-button-container {
  text-align: center;
  margin-top: 30px;
}

.chat-modal .modal-button-container .custom-button {
  margin: 10px 10px;
}

/* Close button */
.chat-modal .close {
  color: #07032b;
  margin-right: 0.5rem;
  float: right;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 10px;
}

.chat-modal .close:hover,
.chat-modal .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.chat-modal .modal-banner {
  border-radius: 10px 10px 0 0;
  padding: 30px 40px;
  text-align: center;
}

.chat-modal .modal-banner-img {
  max-height: 80px;
}
