.dropdown_wrapper {
  position: relative;
}
.dropdown_wrapper label {
  font-family: "Muli";
  font-size: 0.8rem;
  font-weight: bold;
  color: #07032b;
  color: var(--text-color);
  margin-bottom: 16px;
  display: block;
  letter-spacing: 0.02rem;
}
.dropdown_text {
  width: 100%;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #d8d8d2;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #07032b;
  color: var(--text-color);
  font-family: "Muli";
}
.dropdown_text[readonly] {
  cursor: pointer;
}
.dropdown_text:focus {
  outline: none;
}
.dropdown_menu {
  position: absolute;
  border: 1px solid;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  top: 100%;
  border: 1px solid #bec5d9;
  z-index: 12;
  max-height: 280px;
  overflow-y: auto;
}

.dropdown_menu_list {
  width: 100%;
  text-align: left;
  padding: 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-family: "Muli";
  color: #07032b;
  color: var(--text-color);
}

.dropdown_menu_list:hover,
.dropdown_menu_list.hover {
  background-color: #bec5d9;
}
.dropdown_wrapper .arrow-down {
  position: absolute;
  top: 18px;
  right: 16px;
  z-index: 1;
  font-size: 0.4rem;
  color: #07032b;
}

.css-1uccc91-singleValue {
  color: #07032b !important;
  color: var(--text-color) !important;
}

.css-mxetbz-control {
  border-color: #bec5d9;
  font-size: 0.8rem;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.dropdown_wrapper .dropdown-description {
  font-size: 0.65rem;
  font-weight: 600;
  color: #5f5d73;
  margin-bottom: 8px;
}

.nested-optgroup-label {
  color: #07032b;
  font-weight: bold;
}
