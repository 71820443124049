.dropdown_wrapper {
  position: relative;
  z-index: 3;
}
.dropdown_wrapper label {
  font-family: "Muli";
  font-size: 0.8rem;
  font-weight: bold;
  color: #07032b;
  margin-bottom: 10px;
  display: block;
  letter-spacing: 0.02rem;
}
.dropdown_text {
  width: 100%;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #d8d8d2;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #07032b;
  font-family: "Muli";
}
.dropdown_text[readonly] {
  cursor: pointer;
}
.dropdown_text:focus {
  outline: none;
}
.dropdown_menu {
  position: absolute;
  border: 1px solid;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  top: 100%;
  border: 1px solid #ccc;
  z-index: 12;
  max-height: 280px;
  overflow-y: auto;
}

.dropdown_menu_list {
  width: 100%;
  text-align: left;
  padding: 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-family: "Muli";
  color: #07032b;
}
.dropdown_menu_list:hover,
.dropdown_menu_list.hover {
  background-color: #ccc;
}
.dropdown_wrapper .arrow-down {
  position: absolute;
  top: 18px;
  right: 15px;
  z-index: 1;
  font-size: 0.4rem;
  color: #6a6a6a;
}
.Select-menu-outer {
  z-index: 3;
}
.checkbox-dropdown__menu-notice--no-options {
  color: #605D61 !important;
}