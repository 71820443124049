.form-submission-message-page {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 30px;
}

.form-submission-message-page .message-content-wrapper {
  flex: 1 1;
  padding: 10%;
}

.form-submission-message-page .message-content-wrapper-convo {
  padding: 10%;
}

.form-submission-message-page .message-content {
  text-align: center;
}

.form-submission-message-page .message-content ul,
.form-submission-message-page .message-content ol {
  text-align: left;
}

.form-submission-message-page .heading {
  margin: 24px 0 16px 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.form-submission-message-page .form-submission-message {
  font-size: 0.9rem;
  margin: auto;
}

.form-submission-message-page .icon-tick,
.form-submission-message-page .icon-warning {
  font-size: 70px;
}

.form-submission-message-page .footer {
  text-align: center;
}

.form-submission-message-page .form-success-gif {
  max-width: 70px;
}

.form-success-gif-container {
  display: flex;
  justify-content: center;
}