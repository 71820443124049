.custom-icon-label-button {
  border: none;
  font-family: "Muli" !important;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  color: #0923e6;
  cursor: pointer;
  background: none;
}

.custom-icon-label-button:focus {
  outline: none;
}

.custom-icon-label-button-icon {
  margin-right: 8px;
  font-size: 10px;
}

.custom-icon-label-button .download-link {
  text-decoration: none;
  color: #0923e6;
}

.custom-icon-label-red {
  color: #a71b04;
}

.custom-icon-label-button .icon-add::before {
  color: #0923e6;
}
