.all-bookmarks-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px;
  overflow-y: auto;
}
.all-bookmarks-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.all-bookmarks-container::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}
.all-bookmarks-container .all-bookmarks-decor-img {
  position: absolute;
  right: -16px;
  bottom: -16px;
  height: 60px;
  opacity: 0.3;
}

.all-bookmarks-container .all-bookmarks-decor-img img {
  height: 100%;
  width: auto;
}

.all-bookmarks-container .all-bookmarks-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  /* position: sticky; */
  top: 0;
  /* background: #fff; */
  /* padding: 8px 0; */
}
.all-bookmarks-header .custom-icon-label-button{
  padding: 0;
  font-size: 0.875rem;
}
.all-bookmarks-container .all-bookmarks-header .left-partition {
  display: flex;
  line-height: 30px;
}

.all-bookmarks-container .all-bookmarks-header .right-partition {
  padding-top: 4px;
}

.all-bookmarks-container .all-bookmarks-header .header-heading {
  font-size:1rem;
  font-weight: bold;
}

.all-bookmarks-container .header-back-btn {
  background: rgba(255, 168, 114, 0.6);
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 16px;
}

.all-bookmarks-container .header-back-btn .icon-left-chevron::before {
  color: #cc4f00;
}

.all-bookmarks-container .bookmark-item {
  /* border-bottom: 1px solid #eceff7; */
  padding:9px 0;
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  /* cursor: pointer; */
}

.all-bookmarks-container .bookmark-item:hover {
  /* background: #eceff7; */
}

.all-bookmarks-container .bookmark-item-icon {
  font-size: 0.8rem;
  margin-right: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

.all-bookmarks-container .bookmark-item .left-partition {
  display: flex;
}

.all-bookmarks-container .bookmark-item-duration {
  font-size: 0.75rem;
  color: #4B5065;
}

@media screen and (max-width: 768px) {
  .all-bookmarks-container{
    padding: 1.125rem 1rem;
  }
  .all-bookmarks-container .all-bookmarks-header{
    margin-bottom: 1rem;
  }
  .chat-feature-panel-body{
    padding-left: 0;
    padding-right: 0;
  }
  .all-bookmarks-container .bookmark-item{
    padding: 6px 0;
  }
}