.popup-notification-modal {
  background-color: rgba(236, 239, 247, 0.8);
  z-index: 20;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: table;
}

/* Modal content */
.popup-notification-modal .popup-notification-modal-box {
  width: 90%;
  margin: auto;
  padding: 10px;
  /* box-shadow: 0px 0px 15px rgba(215, 214, 230, 0.5); */
  border-radius: 10px;
  padding: 0px;
  position: relative;
  max-height: 500px;
  border: none;
  max-width: 500px;
  display: table-cell;
  vertical-align: middle;
}

.popup-notification-modal .mt-20 {
  margin-top: 20px;
}

.popup-notification-modal .popup-notification-modal-title {
  font-size: 1.2rem;
  letter-spacing: 0.03rem;
  font-family: "Muli";
  font-weight: bold;
  margin-bottom: 16px;
  color: #07032b;
}

.popup-notification-modal .popup-notification-modal-description {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
  margin-top: 5px;
}

.popup-notification-modal .popup-notification-modal-content {
  padding: 32px 32px 30px;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
  position: relative;
  animation-name: zoom-in;
  animation-duration: 0.5s;
}

/* Close button */
.popup-notification-modal .popup-notification-modal-close-btn {
  color: #07032b;
  margin-right: 0.5rem;
  float: right;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
}

.popup-notification-modal-close-btn .icon-close::before {
  color: #07032b;
}

.popup-notification-modal .popup-notification-modal-close-btn:hover,
.popup-notification-modal .popup-notification-modal-close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.popup-notification-modal .popup-notification-modal-img {
  max-width: 180px;
  margin-bottom: 32px;
}

.popup-notification-modal .popup-notification-btn-wrapper {
  margin-top: 24px;
}

.popup-notification-modal .popup-notification-btn-wrapper .notification-btn {
  background-color: transparent;
  border: 1px solid #1e4c88;
  font-size: 12px;
  border-radius: 3px;
  font-weight: bold;
  padding: 5px 16px;
  color: #1e4c88;
  margin: 8px 12px;
}

.popup-notification-modal .popup-notification-modal-description>ul {
  list-style-type: disc;
  text-align: left;
  display: inline-block;
}

ul { 
  list-style-type: disc; 
  list-style-position: inside; 
}
ol { 
  list-style-type: decimal; 
  list-style-position: inside; 
}
ul ul, ol ul { 
  list-style-type: circle; 
  list-style-position: inside; 
  margin-left: 15px; 
}
ol ol, ul ol { 
  list-style-type: lower-latin; 
  list-style-position: inside; 
  margin-left: 15px; 
}

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
