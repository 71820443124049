.notification-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  border-bottom: 1px solid #eceff7;
  padding: 16px 32px;
}

.notification-container:hover,
.notification-time:hover {
  /* opacity: 0.6; */
}

.notification-read {
  background-color: #ffffff;
}

.notification-unread {
  background-color: #f9fafd;
}

.notification-container .notification-content-header {
  justify-content: space-between;
}
.notification-container .notification-content-container {
  margin-left: 20px;
  flex: 1;
}

.notification-container .notification-icon-container {
  padding-top: 2px;
}
.notification-container .checkbox-container .custom-checkbox {
  width: 13px;
  height: 13px;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #cacece;
  padding: 0px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  content: unset;
  vertical-align: baseline;
  margin-top: 4px;
  margin-left: 10px;
  margin-bottom: 2px;
}

.notification-container .checkbox-container .custom-checkbox:after {
  content: unset;
}

.notification-container .checkbox-container .custom-checkbox:checked:after {
  /* content: ""; */
  width: 0px;
  height: 0px;
  /* top: -1px;
  left: -10px;
  border: 1px solid #f9b234;
  background: #f9b234;
  border-radius: 10px;
  display: inline-block; */
}

.notification-container .checkbox-container .custom-checkbox:checked {
  background-color: #f9b234;
  border: 1px solid #f9b234;
}

.notification-container .option-delete-btn {
  margin-left: 10px;
  border: none;
  font-size: 0.75rem;
  z-index: 100;
  background: transparent;
  cursor: pointer;
}

.notification-container .notification-title {
  display: flex;
  height: auto;
  font-weight: 600;
  font-size: 14px;
}

.notification-container .notification-content {
  /* display: flex; */
  margin: 3px 0px;
  word-wrap: break-word;
  font-weight: 600;
  font-size: 9px;
}

.notification-container .notification-view-more {
  /* display: flex; */
  background: transparent;
  border: none;
  /* word-wrap: break-word; */
  font-weight: 800;
  font-size: 9px;
  cursor: pointer;
}

.notification-container .notification-time {
  font-weight: 600;
  font-size: 7px;
  text-align: right;
}

.notification-container .notification-btn-wrapper {
  margin-top: 12px;
}

.notification-container .notification-btn {
  background-color: transparent;
  border: 1px solid #1e4c88;
  font-size: 10px;
  border-radius: 3px;
  font-weight: bold;
  padding: 3px 10px;
  color: #1e4c88;
  margin: 4px 12px 4px 0;
}

.notification-content .content-format ul {
  list-style-type: disc !important;
  margin-left: 12px;
}

.notification-content .content-format ol {
  list-style: auto;
  margin-left: 10px;
}
