.widget-chat-wrapper .widget-login-screen-message {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  background-color: #0923e6;
  color: #fff;
  margin: 16px 16px 0px;
  border-radius: 40px 40px 0 0;
  padding: 32px;
}

.widget-chat-wrapper .widget-login-screen-form {
  text-align: center;
  margin: 0 16px;
  overflow-y: auto;
  background: #fff;
  height: 100%;
  padding-top: 36px;
}

.widget-login-screen-form .textinput-container {
  text-align: left;
}

.widget-login-screen-form .widget-login-screen-btn {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  background-color: #0923e6;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
}
