.chat-feedback-container {
  /* display: flex; */
  /* box-shadow: 0 -2px 6px rgba(92, 92, 92, 0.08); */
  float: right;
  position: absolute;
  right: 0px;
  bottom: 85px;
  width: 350px;
  height: 250px;
  padding: 12px 32px 24px;
  justify-content: space-between;
  /* border-top: 1px solid #efefef; */
  background-color: #fff;
  z-index: 1;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #c6cbd6;
  overflow: hidden;
  margin: 16px;
  z-index: 200;
}

.chat-feedback-container .avatar-container {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.chat-feedback-container .avatar-container img {
  width: 100%;
}

.chat-feedback-container .input-container {
  flex: 1;
}
.chat-feedback-container .mt-20 {
  margin-top: 20px;
}

.chat-feedback-container .icon-close {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  font-size: 0.8rem;
}

.chat-feedback-container .input-container .input {
  width: 100%;
  box-sizing: border-box;
  font-family: "Muli";
  font-size: 0.9rem;
  color: #07032b;
  border: none;
  height: 31px;
  background-color: #eceff5;
  padding: 24px 24px 26px;
  border-radius: 35px;
  /* border-bottom: 1px solid #eee; */
}

.chat-feedback-container .input-container .input:focus {
  outline: none;
}

.chat-feedback-container .action-container .send-button,
.chat-feedback-container .action-container .end-session-button {
  border: none;
  background: #f9b234;
  background: var(--primary-color);
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  color: #fff;
  color: var(--primary-text-color);
  box-shadow: 0 0 16px rgba(215, 214, 230, 0.8);
  padding: 5px;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  position: relative;
  margin-left: 12px;
}

.chat-feedback-container .textinput-container {
  margin-top: 11px;
}

.chat-feedback-container .custom-button {
  margin-top: 2.1rem;
}
.chat-feedback-container .action-container .end-session-button {
  background: #ff372f;
}

.chat-feedback-container .action-container .send-button .icon-send {
  position: absolute;
  left: 17px;
  top: 14px;
}

.chat-feedback-container .action-container .send-button .icon-send::before {
  color: #fff;
  color: var(--primary-text-color);
  display: inline-block;
  transform: rotate(-45deg);
}

.chat-feedback-container .action-container .send-button:focus {
  outline: none;
}

.chat-feedback-container .action-container {
  padding-top: 5px;
}

.chat-feedback-container .action-container .end-session-button-icon {
  height: 13px;
  width: 13px;
  border-radius: 1px;
  background: #fff;
  position: absolute;
  left: 17px;
  top: 17px;
}

/* media queries */

@media screen and (max-width: 768px) {
  .chat-feedback-container {
    padding: 12px 16px 24px;
  }
}
