:root {
  --primary-color: #f9b234;
  --primary-text-color: #ffffff;
  --user-color: #d8e7fb;
  --user-text-color: #07032b;
  --button-color: #c5d2e3;
  --button-text-color: #07032b;
}

*:focus {
  outline: auto !important;
  outline-width: 2px !important;
  outline-offset: 2px !important;
  outline-color: #D71EF7 !important;
}

body {
  background-color: transparent;
}

a {
  color: #07032b;
  text-decoration: underline;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.flex-1 {
  flex: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.flex-container {
  display: flex;
}

.flex-one {
  flex: 1;
}

.clearfix {
  height: 32px;
}

.half-clearfix {
  height: 16px;
}

.quarter-clearfix {
  height: 8px;
}

a:hover {
  opacity: 0.8;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.page-subheading {
  font-weight: bold;
  font-size: 0.8rem;
  text-align: left;
  margin: 0 0 16px;
}

.side-padding {
  padding: 0 16px;
}

.c-green {
  color: #00864d;
}

.c-blue {
  color: #0923e6;
}

.c-gray {
  color: #6b7085;
}

.c-black {
  color: black !important;
}

.c-pink {
  color: #ff009e;
}

.f-12 {
  font-size: 12px;
}


.f-14{
  font-size: 14px;
}

.f-16{
  font-size: 16px;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700{
  font-weight: 700;
}

.spinner-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-loader {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 2px solid #0923e6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.zindex-ini {
  z-index: initial;
}
