.file-uploader input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.file-uploader {
  position: relative;
  border: 1px solid #5F5E5E;
  background: #fff;
  color: #07032b;
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 0.8rem;
  max-width: 100%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-uploader-icon-wrapper {
  background: #1e4c88;
  font-size: 0.8rem;
  padding: 12px;
  position: absolute;
  top: -1px;
  height: 41px;
  width: 41px;
  text-align: center;
  right: 0px;
  border-radius: 0 0.3rem 0.3rem 0;
}

.file-uploader-icon-wrapper .icon-attach-file::before {
  color: #fff;
}

.file-uploader-container .selected-file-name {
  font-size: 0.7rem;
  margin-top: 0.2rem;
}

.file-uploader-container .info-message {
  color: #0923E6;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}

.file-uploader-container .error-message {
  color: #c34343;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}
