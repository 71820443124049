.profile-modal .modal-title{
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #848282;
  margin-bottom: 0;
}

.profile-modal .chat-modal .modal-body{
  padding: 10px 0px;
  
}

.profile-modal .content-edit {
  max-height: 400px;
  overflow: auto;
}

.profile-modal .modal-button-container{
  display: none;
}

.profile-modal .chat-modal .modal-content {
  max-height: unset;
}

.profile-modal .profile-ava {
  position: relative;
  cursor:pointer;
}

.edit-ava .image {
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
  cursor:pointer;
}

.edit-ava .image.selected {
  border: 3px solid #CDD5EF;
}

.profile-modal .edit-ava {
  padding: 5px 20px;
}

.edit-ava .image.plus {
  background: #ECEFF7;
  position: relative;
}

.profile-modal .edit-ava .plus-icon{
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
}

.profile-modal .image img{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  border-radius: 50%;
}

.edit-ava .image.is-80x80 {
  height: 80px;
  width: 80px;
}

.profile-modal .camera-icon {
  background-image: url(../../assets/images/camera.svg);
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-size: auto;
  border-radius: 50%;
  background-position: center;
  position: absolute;
  bottom: -7px;
  right: 1px;
}

.profile-modal button.secondary {
  color: #0923E6;
  background: white;
}

.profile-modal .footer {
  gap: 16px;
  border-top: 1px solid #848282;
  width: 100%;
  padding: 0 20px;
  padding-top: 10px;
  margin-top: 10px;
}

.profile-modal button {
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #0923E6;
  padding: 6px 16px;
}

.profile-modal button.main {
  color: white;
  background: #0923E6;
}

.profile-modal .tag {
  font-size: 12px;
  border-radius: 50px;
  background: #0923E6;
  color: white;
  padding: 4px 16px;
  margin: 5px;
  display: inline-block;
}

.profile-modal .email {
  font-size: 18px;
  color: #495057;
}

.profile-modal .name {
  font-size: 20px;
  font-weight: 600;
}

.profile-modal .edit-name-title {
  font-size: 16px;
  color: #495057;
}


.profile-modal .edit-name {
  padding: 5px 20px;
}

.profile-modal .edit-name input {
  width: 100%;
  border: 1px solid #848282;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 2rem;
  margin-top: 3px;
  font-size: 16px;
  padding-left: 10px
}

.profile-modal .tag-list {
  padding: 5px 20px;
}

.tag-list .tag-title{
  font-size: 18px;
  padding: 8px 0;
}

.profile-modal .block-ui {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.5;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
