.bot-quiz-bubble-container {
  position: relative;
  width: max-content;
  max-width: 90%;
}

.bot-bubble {
  display: flex;
  position: relative;
  padding-left: 50px;
}

.bot-quiz-bubble-wrapper .loading-bubble {
  background: #fff;
  background: var(--primary-color);
  padding: 16px 24px;
  color: #07032b;
  color: var(--primary-text-color);
  font-weight: 600;
  font-size: 0.85rem;
  width: max-content;
  max-width: 100%;
  word-break: break-word;
  border-radius: 5px;
}

.bot-quiz-bubble-container a {
  color: #07032b;
  text-decoration: underline;
}

.bot-quiz-bubble-wrapper {
  margin-bottom: 24px;
}

.bot-followed-reply-wrapper {
  margin-bottom: 8px;
  margin-top: 8px;
}

.bot-quiz-bubble-container i {
  font-size: 0.9rem;
  color: #ffffff;
  color: var(--primary-text-color);
}

.bot-quiz-bubble-feedback-icon {
  font-size: 0.65rem;
  margin-right: 4px;
  cursor: pointer;
}

.bot-chat-avatar-container {
  width: 38px;
  height: 38px;
  box-shadow: 0 0 10px rgba(215, 214, 230, 0.5);
  border-radius: 50%;
  margin-right: 8px;
  position: absolute;
  bottom: 0px;
  left: 0;
  min-width: 35px;
  min-height: 35px;
  padding: 0px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bot-chat-avatar-container .bot-avatar-img {
  width: 100%;
}

.bot-quiz-bubble-container a {
  color: #07032b;
  text-decoration: underline;
}

.bot-quiz-bubble-container .quiz-question-frame p {
  margin: 0;
}

.bot-quiz-bubble-container .quiz-question-frame {
  border: 1px solid #f9b234;
  border: 1px solid var(--primary-color);
  padding: 16px;
  color: #07032b;
  font-weight: 600;
  font-size: 0.85rem;
  width: max-content;
  max-width: 100%;
  word-break: break-word;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 12px rgba(215, 214, 230, 0.5);
}

.bot-quiz-bubble-wrapper .bot-quiz-input-container {
  width: 100%;
}

.bot-quiz-input-container .quiz-text-input-frame {
  /* max-width: 500px; */
  transition: all 0.1s ease-in-out;
  margin-top: 16px;
  margin-left: 48px;
  padding-right: 3px;
}

.bot-quiz-input-container .send-button .icon-send {
  position: absolute;
  left: 17px;
  top: 14px;
}

.bot-quiz-input-container .send-button .icon-send::before {
  color: #fff;
  color: var(--primary-text-color);
  display: inline-block;
  transform: rotate(-45deg);
}

.bot-quiz-input-container .send-button:focus {
  outline: none;
}

.bot-quiz-input-container .send-button {
  border: none;
  background: #f9b234;
  background: var(--primary-color);
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  color: #fff;
  color: var(--primary-text-color);
  box-shadow: 0 0 16px rgba(215, 214, 230, 0.8);
  padding: 5px;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  position: relative;
  margin-left: 12px;
}

.bot-quiz-input-container .send-button:disabled,
.bot-quiz-input-container .send-button[disabled] {
  background: #bbb;
  opacity: 0.5;
}

.bot-quiz-input-container .quiz-text-input-action {
  text-align: right;
  padding: 16px 0;
}

.bot-quiz-input-container .quiz-table-input-action {
  text-align: right;
  padding: 16px 0;
}

.bot-quiz-input-container .quiz-option-input-frame .quiz-option-btn {
  display: block;
  margin-bottom: 8px;
  border: none;
  background-color: #c5d2e3;
  background-color: var(--button-color);
  padding: 8px 16px;
  border-radius: 5px;
  box-shadow: 0 0 24px rgba(215, 214, 230, 0.5);
  width: 100%;
  min-width: 200px;
  max-width: max-content;
  font-weight: 600;
  font-size: 0.85rem;
}

.bot-quiz-input-container .quiz-option-input-frame {
  margin-top: 16px;
  margin-left: 48px;
  width: 100%;
  /* max-width: 500px; */
  transition: all 0.1s ease-in-out;
  padding-right: 48px;
}

.bot-quiz-bubble-wrapper .user-chat-avatar-container {
  bottom: 0;
}

.bot-quiz-bubble-container .quiz-question-frame ul {
  list-style-type: disc !important;
  margin-left: 25px;
  margin-bottom: 5px;
}

.bot-quiz-bubble-container .quiz-question-frame ol {
  list-style: auto;
  margin-left: 25px;
  margin-bottom: 5px;
}

.bot-quiz-bubble-container .quiz-question-frame.isStrong strong {
  font-weight: 700 !important;
}

.bot-quiz-bubble-container .quiz-question-frame.isStrong p {
  font-weight: 400 !important;
}