.user-report-page-container {
  padding: 40px;
  overflow-y: auto;
}
.user-report-page-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
}

.user-report-page-container::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}

.user-report-page-container .page-header {
  background-color: #fff;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #c6cbd6; */
  box-shadow: 0 0 12px rgba(215, 214, 230, 0.5);
}

.user-report-page-container .header-btn {
  border: none;
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: bold;
  color: #0923e6;
  text-align: left;
}

.user-report-page-container .header-btn .icon-left-chevron::before {
  color: #0923e6;
  font-size: 0.6rem;
  margin-right: 8px;
}

.user-report-page-container .header-btn:hover {
  opacity: 0.7;
}

.user-report-page-container .header-heading {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
}

.summary-card {
  box-shadow: 0 4px 4px rgba(185, 182, 224,1);
  border-radius:8px;
  padding: 1rem 1.25rem;
  background-color: #fff;
}
.summary-card + .summary-card {
  margin-left: 1rem;
}

.summary-card .summary-title {
  color: #07032b;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}

.summary-card .summary-value {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.quiz-submissions-table-container {
  box-shadow: 0 16px 16px rgba(185, 182, 224, 0.16);
  border-radius: 10px;
  padding: 24px;
  margin: 16px;
}

.container-card-heading {
  color: #07032b;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.user-report-page-container .page-heading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0;
}

.user-report-page-container .user-report-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  /* position: sticky; */
  top: 0;
  /* background: #fff; */
  /* padding: 8px 0; */
}

.user-report-page-container .user-report-header .left-partition {
  display: flex;
  line-height: 30px;
}

.user-report-page-container .user-report-header .header-heading {
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .user-report-page-container{
    padding: 1.125rem 1rem;
  }
  .user-report-page-container .user-report-header{
    margin-bottom: .5rem;
  }
}