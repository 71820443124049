.suggestion-button {
  border: none;
  background: #c5d2e3;
  background: var(--button-color);
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  font-family: "Muli";
  font-size: 0.8rem;
  padding: 10px 16px;
  color: #07032b;
  color: var(--button-text-color);
  margin-left: 15px;
  margin-bottom: 15px;
  letter-spacing: 0.03rem;
  font-weight: bold;
}

.suggestion-button:focus {
  outline: none;
}
