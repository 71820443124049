.quiz-submission-page-frame {
  /* padding: 16px; */
  box-sizing: border-box;
  position: relative;
  height: 100%;
  overflow-y: auto;
  text-align: center;
  border: 1px solid #f9b234;
  border: 1px solid var(--primary-color);
  background-color: #fff;
  width: 70%;
  border-radius: 5px;
}

.quiz-submission-page-frame-full {
  width: 95%;
}

.quiz-submission-page-frame h4 {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 10px 0;
}

.quiz-submission-page-frame .score-badge {
  width: 70px;
  height: 70px;
  margin: 24px auto 24px;
  background-image: url("../../assets/images/badge.png");
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 21px 0;
}

.quiz-submission-page-frame .score-container {
  width: 100%;
  text-align: center;
}

.quiz-submission-page-frame .button-container {
  text-align: center;
  margin: 16px 0 32px;
}

.quiz-submission-page-frame .charts-container {
  margin: 24px 0 24px;
  background: #f8f9fa;
  border-radius: 5px;
  padding: 24px 0;
}

.quiz-submission-page-frame .charts-container .pie-container {
  height: 250px;
  width: 100%;
  text-align: center;
  max-width: 255px;
  box-sizing: border-box;
  margin: auto;
  display: inline-block;
  padding: 0 20px;
}

.quiz-submission-page-frame .charts-container .chart-heading {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.03rem;
  margin: 10px 0 0;
}

.quiz-submission-page-frame .charts-container .pie-stat-item {
  font-size: 0.8rem;
  font-weight: 600;
}

.quiz-submission-page-frame .total-score-number {
  font-size: 0.65rem;
}

.quiz-submission-page-frame h5 {
  font-weight: bold;
  font-size: 0.8rem;
}

.quiz-submission-page-frame .correct-answers-container {
  margin-top: 40px;
}

.quiz-submission-page-frame .correct-answers-container .accordion-section {
  background: #fff;
}

.quiz-submission-page-frame .answer-table-question {
  text-align: left;
  font-size: 0.8rem;
}

.quiz-submission-page-frame .main-action-container {
  margin: 32px 0;
}

.quiz-submission-page-frame .condition-message {
  font-weight: bold;
  font-size: 0.8rem;
}

.quiz-submission-page-frame .condition-message a {
  color: blue;
}
