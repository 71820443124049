.image-preview-anchor {
  text-decoration: none;
  border: 1px solid #eceff7;
  border-radius: 5px;
  display: block;
  padding: 8px;
  cursor: pointer;
  background-color: #fff;
}

.image-preview-img {
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 3px;
}

.viewer-backdrop {
  background-color: rgba(251, 251, 251, 1);
}

.bot-reply-container .image-preview-anchor {
  background-color: unset;
  border: none;
}

.bot-reply-container .video-preview-wrapper {
  background-color: unset;
  border: none;
}

