.user-chat-bubble-container {
  display: flex;
  position: relative;
  padding: 0 48px 0 0;
  max-width: 100%;
  justify-content: flex-end;
  margin-bottom: 25px;
  word-break: break-word;
}

.user-chat-avatar-container {
  width: 35px;
  height: 35px;
  min-width: 38px;
  min-height: 38px;
  background-size: cover;
  background-position: center;
  /* box-shadow: 0 0 10px rgba(215, 214, 230, 0.8); */
  border-radius: 50%;
  margin-left: 8px;
  position: absolute;
  bottom: 10px;
  right: 0;
  overflow: hidden;
  background-color: transparent;
  padding: 2px;
}

.user-chat-message-container {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: end;
  flex: 1;
}

.user-chat-message {
  border-radius: 35px 35px 0px 35px;
  background: #eceff5;
  background: var(--user-color);
  padding: 1rem 1.6rem 1rem 1.6rem;
  color: #07032b;
  color: var(--user-text-color);
  /* font-weight: 600; */
  font-size: 0.85rem;
  font-weight: 600;
  white-space: pre-wrap;
  cursor: pointer;
  text-align: left;
  max-width: 90%;
  /* background: #fff;
  padding: 1rem 2.8rem 1rem 1.6rem;
  color: #07032b;
  font-weight: 600;
  font-size: 0.85rem;
  border: 1px solid #a5b7cf; */
}

.user-chat-time {
  font-size: 0.70rem;
  color: #0E0748;
  font-weight:500;
  margin-top: 2px;
}

.user-button-container {
  text-align: right;
  float: right;
  min-width: 500px;
  padding: 8px 0;
}

.user-chat-bubble-container .more-menu-wrapper {
  position: absolute;
  right: 52px;
  top: 5px;
}

.user-chat-bubble-container .more-menu-wrapper:hover .more-dropdown-menu,
.user-chat-bubble-container .more-menu-button:hover .more-dropdown-menu {
  display: block;
}

.user-chat-bubble-container .more-dropdown-menu .header-dropdown-menu-item {
  font-family: "Muli";
  font-size: 0.85rem;
  color: #07032b;
  color: var(--user-text-color);
  cursor: pointer;
  letter-spacing: 0.02rem;
  font-weight: 600;
}

.user-chat-bubble-container .more-dropdown-menu {
  right: 0;
}

.user-chat-bubble-container .more-dropdown-menu {
  position: absolute;
  background: #fff;
  top: 30px;
  box-shadow: 0 0 25px rgba(215, 214, 230, 0.6);
  width: max-content;
  max-width: 250px;
  z-index: 200;
  display: none;
  min-width: 100px;
}

.user-chat-bubble-container .more-dropdown-menu .header-dropdown-menu-item {
  padding: 10px 15px;
  cursor: pointer;
  color: #07032b;
  color: var(--user-text-color);
}

.user-chat-bubble-container
  .more-dropdown-menu
  .header-dropdown-menu-item:hover {
  background: #f2f2ed;
}

.user-chat-bubble-container .more-menu-button {
  border: none;
  box-sizing: border-box;
  font-size: 1.1rem;
  cursor: pointer;
  background: none;
  color: #07032b;
  color: var(--user-text-color);
}

.user-chat-bubble-container .more-menu-button:focus {
  outline: none;
}

.user-chat-bubble-container i {
  margin-right: 5px;
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .user-button-container {
    min-width: 90%;
  }
}

.userbubble-bookmark {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0 0;
}

 
.wmi .user-button-container {
    padding: 0 !important;
    margin: 0 !important;
  }
  
.wmi .user-button-container .suggestion-button {
    display: flex;
    flex-wrap: nowrap;
    background: #DFE4EC;
    border-radius: 8px;
    font-size: 1rem !important;
    padding: 10px 12px;
  }
